.CardTournament,.viewTournament {
  min-height: 100%;
  background: #0f0e0e;
  .rs-picker-toggle .rs-btn .rs-btn-default
    {background-color: #232323 !important;
  }
  .rs-picker-default .rs-picker-toggle.rs-btn {
    padding-top: 7px;
    padding-bottom: 7px;
    height: 50px;
    display: flex;
    align-items: center;
    background-color: #232323 !important;
    border: 0;
    svg{
      width: 20px;
      height: 30px;
      display: flex;
      align-items: center;
    }
  }
  .react-select__control {
    outline: 0;
    background: #232323 !important;
    color: #a99898 !important;
    width: 100%;
    border: 0;
    border-radius: 5px;
    margin: 0 0 15px;
    padding: 8px;
    box-sizing: border-box;
    font-size: 14px;
    font-family: "Lato", sans-serif;
  }
  input {
    outline: 0;
    background: #232323;
    color: #FFF !important;
    width: 100%;
    border: 0;

    border-radius: 5px;
    margin: 0 0 15px;
    padding: 15px;
    box-sizing: border-box;
    font-size: 14px;
    font-family: "Lato", sans-serif;
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none !important;
    margin: 0;
  }
}
.calendarSeparator {
  margin: auto;
  height: 24px;
  border-left: 0.5px solid #b9b9b9;
  margin-right: 0.7%;
}
.addIndentDateDiv {
  width: 100%;
}
.icon-cont {
  width: 53px;
  span {
    border-radius: 20%;
    background-color: #e1e2e1;
    display: inline-block;
    height: 38px;
  }
}
.calendarSeparator {
  margin: auto;
  height: 24px;
  border-left: 0.5px solid #b9b9b9;
  margin-right: 0.7%;
}
.TorunamentSubmitButton {
  float: right;
  background-color: rgb(3, 3, 30);
  color: #fff;
  height: 40px;
  margin-right: 30px;
  border: 0.5px solid rgba(188, 164, 164, 0.3);
}
.react-select__single-value{
  padding-left: 10px;
  color: #fff !important;
  // text-transform: capitalize;
}
.react-select__menu{
  z-index: 999 !important;
  background-color: #232323;
  color: #000 !important;
}
.react-select__control {
  outline: 0;
  background: #232323 !important;
  color: #ede1e1 !important;
}
.seeMoreButton{
  background-color: #0f0e0e;
  color: rgb(29, 46, 201);
  padding: 10px 50px;
  border: 1px solid rgb(66, 63, 63);
}
.UserButtons{
  button{
    background-color: #0f0e0e;
    margin: 3px;
    padding: 10px;
  }
}
