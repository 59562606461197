.dashboardHeading {
  background: rgb(19, 19, 19);
  width: 100%;
  color: #fff;
  padding: 10px;
  margin-left: -20px;
  font-family: Poppins;
}
.ListingHeading {
  padding: 30px;
  color: #fff;
  font-family: Poppins;
}
.ParentCard {
  background-color: #3ea570;
  height: 700px;
  .CardHeaderParent {
    background-color: #36835d;
  }
  .cardbackgroungColor {
    background-color: #3ea570 !important;
  }
  .CardHeaderD {
    color: #fff;
    cursor: pointer;
    font-size: 25px;
    font-weight: 900;
    padding: 23px 22px;
  }
  .perfomanceCard {
    height: 300px;
    width: 94%;
    margin: 41px;
    /* margin-top: 200px; */
    // opacity: 0.5;
    background-color: #436643;

    .BestPer {
      margin-top: 108px;
      color: #fff !important;
      font-weight: 1000 !important;
    }
    .subP {
      margin: 15px;
      justify-content: space-evenly;
      .subDiv {
        padding: 10px;
        color: #fff;
        font-weight: 1000;
        text-align: center;
      }
      .TextS {
        font-size: 17px;
        white-space: nowrap;
      }
      .NumberS {
        font-size: 25px;
      }
    }
  }
  .HeaderColor {
    display: flex;
    justify-content: space-around;
    margin: 10px 50px;
    position: relative;
    top: 126px;
    .SubHeaderColor {
      height: 100px;
      background-color: #052005;
      // opacity: 0.8;
      z-index: 99;
      width: 100%;
      margin: 11px;
      .Pdiv {
        padding-top: 20px;
        display: block;
        justify-content: center;
        align-items: center;
        text-align: center;
        .text {
          color: #fff;
          font-weight: 900;
          font-size: 20px;
        }
        .Number {
          color: #fff;
          font-weight: 900;
          font-size: 33px;
        }
      }
    }
  }
}
.parentPro {
  margin-left: 30px;
  position: relative;
  top: -37px;
}
.profileImg {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  background-color: #131111;
}
.logoImg {
  width: 187px;
  height: 160px;
  padding-left: 26px;
  border-radius: 50%;
  padding-top: 20px;
}
.profileNameH {
  align-items: center;
  justify-content: center;
  margin-top: 40px;
  margin-left: 30px;
  font-family: "Poppins";
  .profileNameC1 {
    font-size: 40px;
    color: #0c590c;
    font-weight: 1000;
  }
  .profileNameC2,
  .profileNameC3 {
    font-size: 25px;
    color: #fff;
    font-weight: 1000;
  }
}
.ProfileHeaderImage {
  height: 800px;
  width: 100%;
  object-fit: fill;
}
// media
@media (min-width: 768px) and (max-width:1024px){
  .ProfileHeaderImage {
    height: 600px;
  }
  .profileImg {
    width: 100px;
    height: 100px;
    .logoImg {
      width: 100px;
      height: 100px;
      padding-left: unset;
      padding-top: unset;
    }
  }
  .profileNameC1 {
    font-size: 25px !important;
  }
  .profileNameC2,
  .profileNameC3 {
    font-size: 14px !important;
  }

  .ParentCard {
    margin-left: 20px;
    margin-right: 20px;
    .CardHeaderD {
      font-size: 20px;
      padding: 15px 16px;
      align-items: center;
      display: flex;
    }
  }
    //perfomance Tab
    .ParentPerfomance {
      // width: 113%;
      max-width: 148%;
      overflow: auto;
      .HeaderColor {
        min-width: 200%;
      }
      .perfomanceCard {
        min-width: 200%;
      }
    }
    .ParentCard{
      .perfomanceCard{
        .BestPer{
          font-size: 25px !important;
        }
        .subP{
          .TextS{
            font-size: 18px;
            
          }
          .NumberS{
            font-size: 22px;
          }
        }
      }
      .HeaderColor{
        top: 115px;
        .SubHeaderColor{
          height: 90px;
          .Pdiv{
            .text{
              font-size: 20px;
            }
            .Number{
              font-size: 25px;
            }
          }
        }
      }
    }
  }
@media (min-width: 641px) and (max-width:768px){
  .ProfileHeaderImage {
    height: 450px;
  }
  .profileImg {
    width: 100px;
    height: 100px;
    .logoImg {
      width: 100px;
      height: 100px;
      padding-left: unset;
      padding-top: unset;
    }
  }
  .profileNameC1 {
    font-size: 25px !important;
  }
  .profileNameC2,
  .profileNameC3 {
    font-size: 14px !important;
  }

  .ParentCard {
    margin-left: 20px;
    margin-right: 20px;
    .CardHeaderD {
      font-size: 20px;
      padding: 15px 16px;
      align-items: center;
      display: flex;
    }
  }
    //perfomance Tab
    .ParentPerfomance {
      // width: 113%;
      max-width: 148%;
      overflow: auto;
      .HeaderColor {
        min-width: 200%;
      }
      .perfomanceCard {
        min-width: 200%;
      }
    }
    .ParentCard{
      .perfomanceCard{
        .BestPer{
          font-size: 25px !important;
        }
        .subP{
          .TextS{
            font-size: 16px;
            
          }
          .NumberS{
            font-size: 19px;
          }
        }
      }
      .HeaderColor{
        top: 115px;
        .SubHeaderColor{
          height: 90px;
          .Pdiv{
            .text{
              font-size: 17px;
            }
            .Number{
              font-size: 25px;
            }
          }
        }
      }
    }
  }
@media (min-width: 481px) and (max-width:640px){
  .teamManual{
    padding: 9px 39px !important;
  }
  .ProfileHeaderImage {
    height: 300px;
  }
  .profileImg {
    width: 100px;
    height: 100px;
    .logoImg {
      width: 100px;
      height: 100px;
      padding-left: unset;
      padding-top: unset;
    }
  }
  .profileNameC1 {
    font-size: 25px !important;
  }
  .profileNameC2,
  .profileNameC3 {
    font-size: 14px !important;
  }

  .ParentCard {
    margin-left: 20px;
    margin-right: 20px;
    .CardHeaderD {
      font-size: 17px;
      padding: 15px 16px;
      align-items: center;
      display: flex;
    }
  }
    //perfomance Tab
    .ParentPerfomance {
      // width: 113%;
      max-width: 148%;
      overflow: auto;
      .HeaderColor {
        min-width: 200%;
      }
      .perfomanceCard {
        min-width: 200%;
      }
    }
    .ParentCard{
      .perfomanceCard{
        .BestPer{
          font-size: 25px !important;
        }
        .subP{
          .TextS{
            font-size: 15px;
            
          }
          .NumberS{
            font-size: 17px;
          }
        }
      }
      .HeaderColor{
        top: 115px;
        .SubHeaderColor{
          height: 75px;
          .Pdiv{
            .text{
              font-size: 15px;
            }
            .Number{
              font-size: 25px;
            }
          }
        }
      }
    }
  }
@media (min-width: 381px) and (max-width:480px){
  .teamManual{
    padding: 9px 39px !important;
  }
  .ProfileHeaderImage {
    height: 300px;
  }
  .profileImg {
    width: 100px;
    height: 100px;
    .logoImg {
      width: 100px;
      height: 100px;
      padding-left: unset;
      padding-top: unset;
    }
  }
  .profileNameC1 {
    font-size: 25px !important;
  }
  .profileNameC2,
  .profileNameC3 {
    font-size: 14px !important;
  }

  .ParentCard {
    margin-left: 20px;
    margin-right: 20px;
    .CardHeaderD {
      font-size: 17px;
      padding: 9px 16px;
      align-items: center;
      display: flex;
    }
  }
    //perfomance Tab
    .ParentPerfomance {
      // width: 113%;
      max-width: 148%;
      overflow: auto;
      .HeaderColor {
        min-width: 200%;
      }
      .perfomanceCard {
        min-width: 200%;
      }
    }
    .ParentCard{
      .perfomanceCard{
        .BestPer{
          font-size: 25px !important;
        }
        .subP{
          .TextS{
            font-size: 15px;
            
          }
          .NumberS{
            font-size: 17px;
          }
        }
      }
      .HeaderColor{
        top: 115px;
        .SubHeaderColor{
          height: 75px;
          .Pdiv{
            .text{
              font-size: 15px;
            }
            .Number{
              font-size: 25px;
            }
          }
        }
      }
    }
  }

@media (max-width: 380px) {
  .teamManual{
    padding: 9px 32px !important;
  }
  .ProfileHeaderImage {
    height: 300px;
  }
  .profileImg {
    width: 100px;
    height: 100px;
    .logoImg {
      width: 100px;
      height: 100px;
      padding-left: unset;
      padding-top: unset;
    }
  }
  .profileNameC1 {
    font-size: 25px !important;
  }
  .profileNameC2,
  .profileNameC3 {
    font-size: 14px !important;
  }

  .ParentCard {
    margin-left: 20px;
    margin-right: 20px;
    .CardHeaderD {
      font-size: 13px;
      padding: 9px 16px;
      align-items: center;
      display: flex;
    }
  }
  //perfomance Tab
  .ParentPerfomance {
    // width: 113%;
    max-width: 148%;
    overflow: auto;
    .HeaderColor {
      min-width: 200%;
    }
    .perfomanceCard {
      min-width: 200%;
    }
  }
  .ParentCard{
    .perfomanceCard{
      .BestPer{
        font-size: 25px !important;
      }
      .subP{
        .TextS{
          font-size: 13px;
          
        }
        .NumberS{
          font-size: 15px;
        }
      }
    }
    .HeaderColor{
      top: 115px;
      .SubHeaderColor{
        height: 75px;
        .Pdiv{
          .text{
            font-size: 15px;
          }
          .Number{
            font-size: 25px;
          }
        }
      }
    }
  }
}
