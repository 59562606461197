@font-face {
  font-family: futuraFont;
  src: url(../../assets/fonts/Futura/FuturaLT-CondensedBold.ttf);
}
.fontText {
  font-family: futuraFont;
  // letter-spacing: 1px;
  h1 {
    font-size: 104px;
    line-height: 115px;
    text-transform: uppercase;
  }
}
.desktopDisplayCategory {
  display: flex;
}
.mobileDisplayCategory {
  display: none;
}
.yourJourney {
  position: relative;
  img {
    opacity: 0.5;
    width: 40%;
  }
  .yourJourneyText {
    position: relative;
    top: -375px;
    color: #fff;
    h1 {
      font-family: futuraFont;
      font-size: 104px;
      line-height: 115px;
    }
    h2 {
      font-family: futuraFont;
      font-size: 52px;
      letter-spacing: 1px;
      line-height: 71px;
    }
    span {
      button {
        background-color: #01ffb3;
        padding: 10px 87px;
        font-weight: 700;
        font-size: 53px;
        border-radius: 15px;
        margin-top: 10px;
        color: #000;
      }
    }
    .Redeemtext {
      font-size: 17px;
    }
  }
}
.enrollowBtn {
  span {
    button {
      background-color: #01ffb3;
      padding: 10px 87px;
      font-weight: 700;
      font-size: 53px;
      border-radius: 15px;
      margin-top: 10px;
      color: #000;
    }
  }
  .Redeemtext {
    font-size: 17px;
  }
}
.whatDoyowantCard {
  width: 100%;
  height: 400px;
  border-radius: 15px;
  border: none;
  background-color: white;
  img {
    border-radius: 15px;
  }
}

.cardText {
  padding-left: 20px;
  h1 {
    font-family: "Poppins";
    letter-spacing: 2px;
    font-size: 56px;
    color: #fff;
    font-weight: 900;
  }
  h4,
  p {
    width: 100%;
    font-size: 33px;
    color: rgb(238, 230, 230);
    letter-spacing: 1px;
  }
}

// intermediate
.whatDoyowantCard1 {
  width: 100%;
  height: 300px;
  border-radius: 15px;
  background-color: #000;
}
.cardText1 {
  padding-left: 20px;
  h1 {
    font-family: "Poppins";
    letter-spacing: 2px;
    font-size: 56px;
    color: #fff;
    font-weight: 900;
  }
  h4,
  p {
    width: 100%;
    font-size: 33px;
    color: rgb(238, 230, 230);
    letter-spacing: 1px;
  }
}

.structuredHeader {
  position: absolute;
  top: 440px;
  width: 90%;
  font-size: -66px;
  padding-left: 25px;
  h1 {
    font-size: 30px;
    line-height: 40px !important;
    color: #fff;
  }
}

.structuredDiv2 {
  width: 99%;
  padding-left: 17px;
  font-size: 27px;
  color: #fff;
  padding-top: 15px;
  height: 300px;
}
.sepDiv {
  height: 100px;
}

// break points

@media (min-width: 1300px) and (max-width: 1620px) {
  .yourJourneyText {
    top: -263px !important;
    h1 {
      font-size: 75px !important;
      line-height: 85px !important;
    }
    h2 {
      font-size: 32px !important;
      line-height: 45px !important;
    }
    span {
      button {
        font-size: 34px !important;
      }
    }
  }
  .fontText {
    h1 {
      font-size: 75px;
    }
  }
  .cardText {
    h1 {
      font-size: 40px !important;
    }
    p {
      font-size: 28px;
    }
  }
  .cardText1 {
    h1 {
      font-size: 40px !important;
      line-height: 53px;
    }
    p {
      font-size: 28px;
    }
  }
  // what more caurosel

  .WhatCaurosel {
    img {
      height: 400px;
    }
    .structuredHeader {
      top: 350px;
      h1 {
        font-size: 25px;
      }
    }
    .structuredDiv2 {
      font-size: 22px;
    }
  }
}
@media (min-width: 1224px) and (max-width: 1300px) {
  .yourJourneyText {
    top: -263px !important;
    h1 {
      font-size: 75px !important;
      line-height: 85px !important;
    }
    h2 {
      font-size: 32px !important;
      line-height: 45px !important;
    }
    span {
      button {
        font-size: 34px !important;
      }
    }
  }
  .fontText {
    h1 {
      font-size: 75px;
    }
  }
  .cardText {
    h1 {
      font-size: 40px !important;
    }
    p {
      font-size: 24px;
    }
  }
  .cardText1 {
    h1 {
      font-size: 40px !important;
      line-height: 53px;
    }
    p {
      font-size: 28px;
    }
  }
  // what more caurosel

  .structuredHeader {
    top: 350px;
    h1 {
      font-size: 23px;
    }
  }
  .structuredDiv2 {
    font-size: 23px;
  }
}
@media (min-width: 1081px) and (max-width: 1224px) {
.fontText{
  h1{
    font-size: 64px;
  }
}
}
@media (min-width: 998px) and (max-width: 1224px) {
  .yourJourneyText {
    top: -197px !important;
    h1 {
      font-size: 65px !important;
      line-height: 85px !important;
    }
    h2 {
      font-size: 30px !important;
      line-height: 45px !important;
    }
    span {
      button {
        font-size: 30px !important;
        padding: 8px 56px !important;
      }
    }
  }
  .enrollowBtn {
    span {
      button {
        font-size: 30px !important;
        padding: 8px 56px !important;
     
      }
      .Redeemtext {
        font-size: 10px;
      }
    }
  }
  .Beginner,
  .Intermediate {
    .fontText {
      h1 {
        font-size: 75px;
        line-height: 100px;
      }
    }
  }
  .cardText {
    h1 {
      font-size: 30px !important;
    }
    p {
      font-size: 22px;
    }
  }
  //what do you want
  .whatDoyowantCard {
    height: 300px;
  }
  .cardText {
    h1 {
      font-size: 30px;
      line-height: 38px;
    }
    h4 {
      width: unset;
      font-size: 20px;
    }
  }
  .cardText1 {
    h1 {
      font-size: 38px !important;
      line-height: 53px;
    }
    p {
      font-size: 25px;
    }
  }
}
@media (min-width: 993px) and (max-width: 1224px) {
  .structuredHeader {
    top: 350px;
  }
  .structuredDiv2 {
    font-size: 25px;
  }
}
@media (min-width: 868px) and (max-width: 998px) {
  .yourJourneyText {
    top: -197px !important;
    h1 {
      font-size: 65px !important;
      line-height: 85px !important;
    }
    h2 {
      font-size: 30px !important;
      line-height: 45px !important;
    }
    span {
      button {
        font-size: 30px !important;
        padding: 8px 56px !important;
      }
    }
  }
  .Beginner,
  .Intermediate {
    .fontText {
      h1 {
        font-size: 70px;
        line-height: 100px;
      }
    }
  }
  .cardText {
    h1 {
      font-size: 30px !important;
    }
    p {
      font-size: 20px;
    }
  }
  .cardText1 {
    h1 {
      font-size: 34px !important;
      line-height: 53px;
    }
    p {
      font-size: 25px;
    }
  }
}
@media (min-width: 768px) and (max-width: 868px) {
  .yourJourney {
    img {
      width: 54%;
    }
  }
  .yourJourneyText {
    top: -197px !important;
    h1 {
      font-size: 65px !important;
      line-height: 85px !important;
    }
    h2 {
      font-size: 30px !important;
      line-height: 45px !important;
    }
    span {
      button {
        font-size: 30px !important;
        padding: 8px 56px !important;
      }
    }
  }
  .Beginner,
  .Intermediate {
    .fontText {
      h1 {
        font-size: 60px;
        line-height: 100px;
      }
    }
  }
  .cardText1 {
    h1 {
      font-size: 34px !important;
      line-height: 53px;
    }
    p {
      font-size: 25px;
    }
  }

  .cardText {
    h1 {
      font-size: 24px !important;
      line-height: 32px;
    }
    p {
      font-size: 20px;
    }
  }
}
@media (max-width: 767px) {
  //what do you want
  .desktopDisplayCategory {
    display: none;
  }
  .mobileDisplayCategory {
    display: block;
  }
  .cardText,
  .cardText1 {
    h4 {
      width: unset;
    }
  }
}
@media (min-width: 640px) and (max-width: 767px) {
  .enrollowBtn {
    span {
      button {
        font-size: 34px;
        padding: 10px 60px;
      }
    }
  }
  .yourJourney {
    img {
      width: 54%;
    }
  }
  .yourJourneyText {
    top: -197px !important;
    h1 {
      font-size: 65px !important;
      line-height: 85px !important;
    }
    h2 {
      font-size: 30px !important;
      line-height: 45px !important;
    }
    span {
      button {
        font-size: 30px !important;
        padding: 8px 56px !important;
      }
    }
  }
  .Beginner,
  .Intermediate {
    .fontText {
      h1 {
        font-size: 45px;
        line-height: 100px;
      }
    }
  }
  .cardText1,
  .cardText {
    h1 {
      font-size: 30px !important;
      line-height: 52px;
    }
    p {
      font-size: 20px;
    }
  }
}
@media (max-width: 992px) {
  .structuredDiv2 {
    height: auto;
    padding-bottom: 40px;
  }
}
@media (min-width: 768px) and (max-width: 992px) {
  .enrollowBtn {
    span {
      button {
        font-size: 34px;
        padding: 8px 56px !important;
      }
    }
  }
  .whatMoreParentDiv {
    .slick-initialized .slick-slide {
      padding: 7%;
      padding-right: 40px;
      display: flex;
      justify-content: center;
    }
  }
  .slick-next:before,
  .slick-prev:before {
    font-size: 57px;
  }
  .structuredHeader {
    h1 {
      font-size: 45px;
    }
  }
}
@media (min-width: 640px) and (max-width: 767px) {
  .whatMoreParentDiv {
    .slick-initialized .slick-slide {
      padding: 7%;
      padding-right: 154px;
      display: flex;
      justify-content: center;
    }
  }
}
@media (min-width: 480px) and (max-width: 640px) {
  .enrollowBtn {
    span {
      button {
        font-size: 34px;
        padding: 8px 56px !important;
      }
    }
  }
  .yourJourney {
    img {
      width: 64%;
    }
  }
  .yourJourneyText {
    top: -197px !important;
    h1 {
      font-size: 55px !important;
      line-height: 65px !important;
    }
    h2 {
      font-size: 25px !important;
      line-height: 45px !important;
    }
    span {
      button {
        font-size: 30px !important;
        padding: 8px 56px !important;
      }
    }
  }
  .Beginner,
  .Intermediate {
    .fontText {
      h1 {
        font-size: 35px;
        line-height: 100px;
      }
    }
  }
  .cardText1,
  .cardText {
    h1 {
      font-size: 30px !important;
      line-height: 52px;
    }
    p {
      font-size: 20px;
    }
  }
  .structuredDiv2 {
    font-size: 22px;
  }
}
@media (min-width: 380px) and (max-width: 480px) {
  .structuredDiv2 {
    font-size: 16px;
  }
  // your journey
  .enrollowBtn {
    span {
      button {
        
        font-size: 20px !important;
        padding: 8px 26px !important;
      }
      .Redeemtext {
        font-size: 10px;
      }
    }
  }
  .yourJourney {
    img {
      width: 64%;
    }
  }
  .yourJourneyText {
    top: -157px !important;
    h1 {
      font-size: 40px !important;
      line-height: 45px !important;
    }
    h2 {
      font-size: 20px !important;
      line-height: 35px !important;
    }
    span {
      button {
        font-size: 20px !important;
        padding: 8px 26px !important;
      }
      .Redeemtext {
        font-size: 12px !important;
      }
    }
  }
  .Beginner,
  .Intermediate {
    .fontText {
      h1 {
        font-size: 30px;
        line-height: 10px;
      }
    }
  }

  // what do you want
  .whatDoyowantCard {
    height: 300px;
  }
  .cardText1,
  .cardText {
    h1 {
      font-size: 30px !important;
      line-height: 52px;
    }
    p {
      font-size: 20px;
    }
  }
  .structuredHeader {
    top: 345px;
    padding-left: 16px;
    width: 100%;
  }
  .structuredDiv2 {
    font-size: 23px;
  }
}
@media (max-width: 380px) {
  // header font
  .fontText {
    h1 {
      font-size: 29px;
    }
  }
  .enrollowBtn {
    span {
      button {
        font-size: 16px !important;
        padding: 8px 20px !important;
      }
      .Redeemtext {
        font-size: 10px;
      }
    }
  }
  // your journey

  .yourJourney {
    img {
      width: 64%;
    }
  }
  .yourJourneyText {
    top: -107px !important;
    h1 {
      font-size: 30px !important;
      line-height: 35px !important;
    }
    h2 {
      font-size: 16px !important;
      line-height: 35px !important;
    }
    span {
      button {
        font-size: 16px !important;
        padding: 8px 20px !important;
      }
      .Redeemtext {
        font-size: 10px !important;
      }
    }
  }
  .Beginner,
  .Intermediate {
    .fontText {
      h1 {
        font-size: 25px;
        line-height: 10px;
      }
    }
  }
  .cardText1,
  .cardText {
    h1 {
      font-size: 30px !important;
      line-height: 52px;
    }
    p {
      font-size: 20px;
    }
  }
  // what do you want
  .whatDoyowantCard {
    height: 200px;
  }
  .cardText,
  .cardText1 {
    h1 {
      font-size: 24px;
    }
    h4 {
      font-size: 17px;
    }
  }
  .structuredHeader {
    top: 251px;
    padding-left: 16px;
    width: 100%;
  }
  .structuredDiv2 {
    font-size: 23px;
  }
}
@media (min-width:992px) and (max-width:1224px){
  .whatDoyowantCard1{
    height: 200px;
   
  }
  .video-section{
    height: 400px;
  }
}
@media (min-width:481px) and (max-width:991px){
  .whatDoyowantCard1{
    height: 350px;
    .video-section{
      height: 300px;
    }
  }
}
@media (max-width:480px){
  .whatDoyowantCard1{
    height: 200px;
    .video-section{
      height: 100%;
    }
  }
}