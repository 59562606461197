@font-face {
  font-family: futuraFont;
  src: url(../../assets/fonts/Futura/FuturaLT-CondensedBold.ttf);
}
.MainvalorantPage {
  background-image: url("../../assets/images/CoachesAsset2.png");
  background-repeat: no-repeat;
  background-position-x: 123%;
  background-size: 60%;
}
.MainvalorantPage2 {
  background-image: url("../../assets/images/CoachesAsset6.png");
  background-repeat: no-repeat;
  background-position-x: -29%;
  background-size: contain;
  background-position-y: -112px;
}
.PaddingTopImage {
  padding-top: 300px;
}

.UpperHeading {
  display: flex;
  text-align: center;
  justify-content: center;
  span {
    font-family: futuraFont;
    // background-color: #352903;
    color: white !important;
    font-size: 74px !important;
    font-weight: 900 !important;
    text-transform: uppercase;
    // background-color: #352903;
    padding: 30px;
    width: fit-content;
    margin: auto;
    text-align: center;
    word-spacing: 1px;
    letter-spacing: 2px;
    font-weight: bolder !important;
  }

  .selectRankSpan {
    font-family: Poppins;
    font-size: 52px !important;
    color: black !important;
    // background-color: #83d9cd;
    padding: 20px;
    margin: auto;
    // margin-top: 50px;
    text-align: center;
    border-radius: 20px;
    text-transform: uppercase;
    font-weight: bolder !important;
    word-spacing: 1px;
    letter-spacing: 1px;
    // font-family: futuraFont;
  }
  .SelectDeopdown {
    margin-top: 50px;
  }
  .DropdownButton {
    background-color: #83d9cd;
    border-radius: 10px;
  }
}
.sepDivV1 {
  height: 200px;
}

.ThreeDivSection {
  margin: 0 37px !important;
  margin-top: 40px !important;
  .beginnerDiv {
    height: 1030px;
    background-color: #83d9cd;
    position: relative;
    display: flex;
    justify-content: center;
    text-align: center;
  }
  .addvanceProgramDiv {
    background-color: #82c1a4;
    height: 1600px !important;
    position: relative;
    display: flex;
    justify-content: center;
    text-align: center;
  }
  .interMediateDiv {
    height: 1300px;
    background-color: #d09f90;
    position: relative;
    display: flex;
    justify-content: center;
    text-align: center;
  }
  .beginnerDiv,
  .interMediateDiv,
  .addvanceProgramDiv {
    // .imageBeginner{
    //   height: 100%;
    //   margin-top: 19px;
    // }
    // .imageIntermediate{
    //   height: 80%;
    // }
    // .imageAddvance{
    // height: 70%;
    // }
    .CardHeaderDiv {
      font-family: Poppins;
      font-size: 36px;
      font-weight: 900;
      padding: 30px 10px;
      white-space: nowrap;
      position: absolute;
    }
    .ContentPara {
      font-family: Poppins;
      position: absolute;
      top: 379px;
      font-size: 20px;
      width: 100%;
    padding: 0px 20px;
    }
    .LastDivOff {
      position: absolute;
      width: 67%;
      align-items: center;
      top: 863px;
    }
    .LastDivOff1 {
      position: absolute;
      width: 67%;
      align-items: center;
      top: 1164px;
    }
    .LastDivOff2 {
      position: absolute;
      width: 67%;
      align-items: center;
      top: 1455px;
    }
    .LastDivOff,
    .LastDivOff1,
    .LastDivOff2 {
      .OffNum {
        font-size: 24px;
        font-weight: 700;
        font-style: normal;
        font-family: Poppins;
      }
      span {
        del {
          font-size: 12px;
          font-weight: 500;
          font-style: normal;
          font-family:Poppins;
        }
      }
      .fiftyOff {
        font-size: 30px;
        color: #000;
        font-family: Poppins;

      }
      .BuyNowBtn {
        font-family: Poppins;
        // width: 200px;
        color: #fff;
        font-size: 24px;
        font-weight: 900;
        border-radius: 15px;
        box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);
      }
      .MoreInfo {
        font-size: 17px;
        cursor: pointer;
        padding: 10px;
        font-family: Poppins;

      }
    }
  }
  .beginnerDiv,
  .interMediateDiv {
    .IconsHeader {
      position: absolute;
      align-items: center;
      top: 562px;
      .IconsRow {
        padding: 10px;
        .iconContent {
          font-family: Poppins;
          text-align: left;
          // text-transform: capitalize;
          font-size: 20px;
        }
      }
      img {
        width: 100%;
        // height: 100%;
      }
    }
  }
  .addvanceProgramDiv {
    .IconsHeader1 {
      position: absolute;
      align-items: center;
      top: 506px;
      padding-left: 45px;
      .IconsRow {
        padding: 10px;
        .iconContent {
          font-family: Poppins;
          text-align: left;
          // text-transform: capitalize;
          font-size: 20px;
        }
      }
      img {
        width: 60%;
        // height: 100%;
      }
    }
  }
}
.CauroselDivYours {
  .YourwordCarousal1 {
    padding: 87px !important;
    opacity: 0.8;
    span {
      font-size: 34px !important;
      font-weight: 900 !important;
      color: white;
    }
  }
  .YourwordCarousal2 {
    margin-top: 30px;
    // background-color: #040503 !important;
    padding: 30px !important;
    span {
      font-size: 34px !important;
      font-weight: 900 !important;
      color: white;
    }
  }
}
.CoachesCarousal1 {
  position: relative;
  // background-color: #040503 !important;
  opacity: 0.8;
  margin-top: 40px !important;
  width: 500px !important;
  height: 300px !important;
  margin-right: 20px !important;

  .Bgdiv {
    height: 250px;
    width: 300px;
    // background-color: #06834a;
    position: relative;
    left: 15%;
  }
  .Bgimg1 {
    position: absolute;
    top: 6px;
    left: 3px;
    width: 238px;
    height: 288px;
  }
  .Bgimg2 {
    position: absolute;
    top: 31px;
    left: 3px;
    width: 182px;
    height: 288px;
  }
  .Bgimg3 {
    position: absolute;
    top: 31px;
    left: -14px;
    width: 245px;
    height: 288px;
  }
  .Bgimg4 {
    position: absolute;
    top: 12px;
    left: -29px;
    width: 299px;
    height: 278px;
  }
  .BgdivContent {
    position: absolute;
    top: 40px;
    left: 197px;
    color: white;
    font-size: 20px;
    text-align: right;
  }
}

.borderIntermediateProgram{
  opacity: 0.5;
  

}
.borderIntermediateProgram1{
  opacity: 0.5;
 
}

// media queries
// @media(min-width:1124px) {
  .beginnerDiv,
  .interMediateDiv,
  .addvanceProgramDiv {
    .imageBeginner{
      height: 100%;
      margin-top: 19px;
    }
    .imageIntermediate{
      height: 80%;
    }
    .imageAddvance{
    height: 70%;
    }
  }
// }
@media (min-width:1599px){
.ContentPara{
  font-size: 24px !important;
}
.LastDivOff,.LastDivOff1,.LastDivOff2{
  .NumberCutt{
    width: 60%;
  }
  .fiftyOff{
    width: 60%;
  }
}
}
@media (min-width: 1200px) and (max-width: 1260px) {
.ThreeDivSection{
  .addvanceProgramDiv{
    height: 1700px !important;
  }
  .LastDivOff2{
    top: 1600px !important;
  }
}
}
@media (min-width: 1200px) and (max-width: 1400px) {
  .ThreeDivSection {
    .CardHeaderDiv {
      font-size: 26px !important;
    }
    .BuyNowBtn {
      font-size: 20px !important;
      width: 132px !important;
    }
  }
  .MainvalorantPage {
    background-size: 71%;
    background-position-y: 26%;
  }
 
}
@media (min-width: 1224px) and (max-width: 1300px) {
  .CoachesCarousal1 {
    .BgdivContent {
      position: absolute;
      top: 42px;
      left: 197px;
      color: white;
      font-size: 17px;
      text-align: right;
      padding-right: 65px;
    }
  }
}
@media (min-width: 640px) and (max-width: 1223px) {
  .slick-initialized .slick-slide {
    display: block;
    display: flex;
    justify-content: center;
    padding: 30px;
  }
  .CoachesCarousal1 {
    .Bgdiv {
      width: 100%;
      left: unset;
    }
    .Bgimg1 {
      left: -11%;
      width: 284px;
      top: 0px;
    }
    .Bgimg4 {
      left: -10%;
    }
    .Bgimg3 {
      left: -8%;
      width: 305px;
    }
  }
}
@media (min-width: 900px) and (max-width: 1024px) {
  .MainvalorantPage {
    background-size: 84%;
    background-position-y: 24%;
    background-position-x: 190%;
  }
  .PaddingTopImage {
    padding-top: 200px !important;
  }
  .LastDivOff {
    top: 652px !important;
  }
  .LastDivOff1 {
    top: 900px !important;
  }
  .LastDivOff2 {
    top: 1200px !important;
  }
  .beginnerDiv {
    height: 740px !important;
  }
  .interMediateDiv {
    height: 990px !important;
  }
  .addvanceProgramDiv {
    height: 1290px !important;
  }
 
}
@media (min-width: 1025px) and (max-width: 1199px) {
  .CauroselDivYours {
    .YourwordCarousal1,
    .YourwordCarousal2 {
      span {
        font-size: 30px !important;
      }
    }
  }
  .MainvalorantPage {
    background-size: 71%;
    background-position-y: 26%;
  }
  .LastDivOff {
    top: 652px !important;
  }
  .LastDivOff1 {
    top: 900px !important;
  }
  .LastDivOff2 {
    top: 1330px !important;
  }
  .beginnerDiv {
    height: 740px !important;
  }
  .interMediateDiv {
    height: 990px !important;
  }
  .ThreeDivSection .addvanceProgramDiv {
    height: 1430px !important;
  }
}
@media (min-width: 963px) and (max-width: 1199px) {
  .ContentPara{
    width: 90%;
    top: 287px !important;
  }
  .IconsHeader{
    width: 97% ;
    img{
       width: 80% !important;
    }
  }
  .IconsHeader1{
    width: 97%  !important;
    img{
       width: 70% !important;
    }
  }
  .CauroselDivYours {
    .YourwordCarousal1,
    .YourwordCarousal2 {
      span {
        font-size: 30px !important;
      }
    }
  }
  .ThreeDivSection {
    .imageAddvance{
      height: 52% !important;
    }
    .CardHeaderDiv {
      font-size: 20px !important;
    }

    .ContentPara {
      font-size: 16px !important;
    }
    .BuyNowBtn {
      font-size: 14px !important;
      width: auto !important;
    }

    .iconContent {
      font-size: 16px !important;
    }
    .LastDivOff,
    .LastDivOff1,
    .LastDivOff2 {
      width: 90% !important;
      .OffNum {
        font-size: 15px !important;
      }
      .fiftyOff {
        font-size: 20px !important;
      }
    }
    .IconsHeader1 {
      top: 385px !important;
      padding-left: 0px !important;
     
    }
  }
  .IconsHeader {
    top: 430px !important;
  }
}
@media (min-width: 900px) and (max-width: 962px) {
  .ContentPara{
    width: 90%;
    top: 291px !important;
    font-size: 14px !important;
  }
  .IconsHeader{
    width: 97% ;
    img{
       width: 80% !important;
    }
  }
  .IconsHeader1{
    width: 97%  !important;
    img{
       width: 70% !important;
    }
  }
  .CauroselDivYours {
    .YourwordCarousal1,
    .YourwordCarousal2 {
      span {
        font-size: 30px !important;
      }
    }
  }
  .ThreeDivSection {
    .imageAddvance{
      height: 71% !important;
    }
    .CardHeaderDiv {
      font-size: 20px !important;
    }

    
    .BuyNowBtn {
      font-size: 14px !important;
      width: auto !important;
    }

    .iconContent {
      font-size: 14px !important;
    }
    .LastDivOff,
    .LastDivOff1,
    .LastDivOff2 {
      width: 90% !important;
      .OffNum {
        font-size: 15px !important;
      }
      .fiftyOff {
        font-size: 20px !important;
      }
    }
    .addvanceProgramDiv{
      height: 1200px !important;
    }
    .LastDivOff2{
      top: 1100px !important;
    }
    .IconsHeader1 {
      top: 385px !important;
      padding-left: 0px !important;
     
    }
  }
  .IconsHeader {
    top: 430px !important;
  }
  .MoreInfo{
    font-size: 16px !important;
  }
}
@media (min-width: 800px) and (max-width: 899px) {
  .ThreeDivSection{
    .beginnerDiv,.interMediateDiv,.addvanceProgramDiv{
      margin: 30px 70px;
         }
  }
}
@media (min-width: 700px) and (max-width: 899px) {
  .ThreeDivSection {
    margin: 0px 130px !important;
  }
  .ThreeDivSection{
    .beginnerDiv,.interMediateDiv,.addvanceProgramDiv{
      .CardHeaderDiv{
        font-size: 30px !important;
      }    }
  }
  
}
@media (min-width: 800px) and (max-width: 868px) {
  .ThreeDivSection {
    .BuyNowBtn{
      width: auto !important;
    }
  }
}
@media (min-width: 700px) and (max-width: 768px) {
  .ThreeDivSection {
    .BuyNowBtn{
      width: auto !important;
    }
  }
}
@media (min-width: 640px) and (max-width: 899px) {
  .slick-initialized .slick-slide {
    display: block;
    display: flex;
    justify-content: center;
    padding: 30px;
  }
  .CoachesCarousal1 {
    .Bgdiv {
      width: 100%;
      left: unset;
    }
    .Bgimg1 {
      left: -11%;
      width: 284px;
      top: 0px;
    }
    .Bgimg4 {
      left: -10%;
    }
    .Bgimg3 {
      left: -8%;
      width: 305px;
    }
  }
  .CauroselDivYours {
    .YourwordCarousal1,
    .YourwordCarousal2 {
      span {
        font-size: 25px !important;
      }
    }
  }
  .MainvalorantPage2 {
    background-position-y: -30px;
  }
  .MainvalorantPage {
    background-size: 84%;
    background-position-y: 3%;
    background-position-x: 229%;
  }
  .PaddingTopImage {
    padding-top: 100px !important;
  }
  .UpperHeading {
    margin-top: 100px;
    span {
      font-size: 50px !important;
    }
    .selectRankSpan {
      font-size: 30px !important;
    }
  }
  .sepDivV1 {
    height: 100px;
  }
}
@media (min-width: 480px) and (max-width: 639px) {
  .ThreeDivSection .beginnerDiv .IconsHeader img,
  .ThreeDivSection .interMediateDiv .IconsHeader img {
    width: 81%;
  }
  .ThreeDivSection{
    .beginnerDiv,.interMediateDiv,.addvanceProgramDiv{
      .CardHeaderDiv{
        font-size: 24px;
      }    }
  }
  
  .ContentPara{
    width: 82%;
  }
  .MainvalorantPage2 {
    background-position-y: -5px;
  }
  .MainvalorantPage {
    background-size: 89%;
    background-position-y: 4%;
    background-position-x: 229%;
  }
  .PaddingTopImage {
    padding-top: 50px !important;
  }
  .UpperHeading {
    margin-top: 100px;
    span {
      font-size: 40px !important;
    }
    .selectRankSpan {
      font-size: 20px !important;
    }
  }
  .sepDivV1 {
    height: 80px;
  }
  .IconsHeader1 {
    padding-left: 10px !important;
    width: 87% !important;
    img {
      width: 60% !important;
    }
  }
  .BuyNowBtn {
    width: auto !important;
  }
}
@media (min-width: 380px) and (max-width: 399px) {
  .ThreeDivSection {
    .addvanceProgramDiv {
      height: 1557px !important;
    }}
    .LastDivOff2 {
      top: 1440px !important;
    }
}
@media (min-width: 480px) and (max-width: 489px) {
  .ThreeDivSection {
    .addvanceProgramDiv {
      height: 1750px !important;
    }}
    .LastDivOff2 {
      top: 1600px !important;
    }
}
@media (min-width: 426px) and (max-width: 479px) {
  .ThreeDivSection {
    .addvanceProgramDiv {
      height: 1450px !important;
    }}
    .LastDivOff2 {
      top: 1300px !important;
    }
}
@media (min-width: 400px) and (max-width: 425px){
  .ThreeDivSection {
    .addvanceProgramDiv {
      height: 1557px !important;
      .IconsHeader1 {
        width: 90% !important;
        top: 400px !important;
        padding-left: 10px !important;
        img {
          width: 71% !important;
        }
      }
      .LastDivOff2{
        top: 1447 !important;
      }
    }
  }
}
@media (min-width: 380px) and (max-width: 479px) {
  .ThreeDivSection .beginnerDiv .IconsHeader img,
  .ThreeDivSection .interMediateDiv .IconsHeader img {
    width: 71%;
  }
  .ContentPara{
    width: 82%;
    top: 305px !important;
    padding: 0px 15px !important;
  }
  .CauroselDivYours {
    .YourwordCarousal1,
    .YourwordCarousal2 {
      span {
        font-size: 25px !important;
      }
    }
  }
  .CauroselDivYours {
    .YourwordCarousal1 {
      padding: 41px !important;
      margin-top: 50px;
    }
  }
  .MainvalorantPage2 {
    background-position-y: -5px;
  }
  .MainvalorantPage {
    background-size: 95%;
    background-position-y: 2%;
    background-position-x: 512%;
  }
  .PaddingTopImage {
    padding-top: 50px !important;
  }
  .UpperHeading {
    margin-top: 100px;
    span {
      font-size: 30px !important;
    }
    .selectRankSpan {
      font-size: 15px !important;
    }
  }
  .sepDivV1 {
    height: 50px;
  }
  .ThreeDivSection {
    .CardHeaderDiv {
      font-size: 22px !important;
      padding: unset !important;
      padding-top: 30px !important;
    }
    .imageAddvance{
      height: 57%;
      position: relative;
      left: 7%;
  

    }
    .imageIntermediate{
      height: 75%;
      position: relative;
      left: 7%;
    }
  }
  .ContentPara,
  .iconContent {
    font-size: 18px !important;
  }
  .IconsHeader {
    top: 467px !important;
  }
  .LastDivOff {
    top: 681px !important;
  }
  .beginnerDiv {
    height: 800px !important;
  }
  .LastDivOff,
  .LastDivOff1,
  .LastDivOff2 {
    width: 95% !important;
    .OffNum {
      font-size: 15px !important;
    }
    .BuyNowBtn {
      width: auto !important;
      font-size: 15px !important;
    }
  }

  .interMediateDiv {
    height: 1090px !important;
    .LastDivOff1 {
      top: 983px !important;
    }
  }
  .ThreeDivSection {
    .addvanceProgramDiv {
      // height: 1557px !important;
      .IconsHeader1 {
        width: 90% !important;
        top: 400px !important;
        padding-left: 10px !important;
        img {
          width: 71% !important;
        }
      }
      
    }
  }
}
@media (max-width: 379px) {
  .imageAddvance,.imageIntermediate{
    position: relative;
    left: 7%;
  }
  .ThreeDivSection .beginnerDiv .IconsHeader img,
  .ThreeDivSection .interMediateDiv .IconsHeader img {
    width: 61%;
  }
  .ContentPara{
    width: 82%;
    top: 299px !important;
  }
  .CauroselDivYours {
    .YourwordCarousal1,
    .YourwordCarousal2 {
      span {
        font-size: 18px !important;
      }
    }
  }
  .CauroselDivYours {
  
      .slick-prev{
        left: 1% !important;
      }
      .slick-next{
        right: 5% !important;
      }
      .slick-next, .slick-prev {
        z-index: 99999;
        top: 43%;
      }
      .slick-next:before, .slick-prev:before {
        font-size: 50px;
        
    }
    .YourwordCarousal1 {
      padding: 41px !important;
      margin-top: 50px;
    }
  }
  .MainvalorantPage2 {
    background-position-y: -5px;
  }
  .MainvalorantPage {
    background-size: 95%;
    background-position-y: 2%;
    background-position-x: 512%;
  }
  .PaddingTopImage {
    padding-top: unset !important;
  }
  .ThreeDivSection {
    .CardHeaderDiv {
      font-size: 18px !important;
      padding: unset !important;
      padding-top: 30px !important;
    }
  }
  .UpperHeading {
    margin-top: 100px;
    span {
      font-size: 25px !important;
    }
    .selectRankSpan {
      font-size: 10px !important;
    }
  }
  .sepDivV1 {
    height: 30px;
  }
  .ContentPara,
  .iconContent {
    font-size: 15px !important;
  }
  .IconsHeader {
    top: 441px !important;
  }
  .LastDivOff {
    top: 661px !important;
  }
  .beginnerDiv {
    height: 780px !important;
  }
  .LastDivOff,
  .LastDivOff1,
  .LastDivOff2 {
    width: 95% !important;
    .OffNum {
      font-size: 15px !important;
    }
    .BuyNowBtn {
      width: auto !important;
      font-size: 15px !important;
    }
  }

  .interMediateDiv {
    height: 990px !important;
    .LastDivOff1 {
      top: 873px !important;
    }
  }
  .ThreeDivSection {
    .addvanceProgramDiv {
      height: 1217px !important;
      .IconsHeader1 {
        top: 380px !important;
        padding-left: 10px !important;
        img {
          width: 71% !important;
        }
      }
      .LastDivOff2 {
        top: 1105px !important;
      }
    }
  }
}
@media (min-width:320px) and (max-width:360px){
 
  .ThreeDivSection {
    .BuyNowBtn{
      font-size: 12px !important;
    }
    .addvanceProgramDiv {
      height: 1417px !important;
      .ContentParaMA{
        top: 330px !important;
      }
      .IconsHeader1 {
        top: 430px !important;
        padding-left: 10px !important;
        img {
          width: 71% !important;
        }
      }
      .LastDivOff2 {
        top: 1300px !important;
      }
    
    }
  }
}
// coaches Div

@media (min-width: 560px) and (max-width: 639px) {
  .slick-initialized .slick-slide {
    display: block;
    display: flex;
    justify-content: center;
    padding: 30px;
  }
  .CoachesCarousal1 {
    .Bgdiv {
      width: 69%;
    }
    .BgdivContent {
      font-size: 17px;
      left: 36%;
      width: 53%;
    }
  }
}
@media (min-width: 480px) and (max-width: 559px) {
  .slick-initialized .slick-slide {
    display: block;
    display: flex;
    justify-content: center;
    padding: 30px;
  }
  .CoachesCarousal1 {
    .Bgdiv {
      width: 69%;
    }
    .BgdivContent {
      font-size: 17px;
      left: 36%;
      width: 53%;
    }
  }
  .Bgimg2 {
    left: 9%;
  }
  .Bgimg3 {
    left: 4% !important;
  }
  .Bgimg4 {
    left: 1% !important;
    top: 35px;
    width: 246px !important;
    height: 234px;
  }
}
@media (min-width: 380px) and (max-width: 479px) {
  .slick-initialized .slick-slide {
    display: block;
    display: flex;
    justify-content: center;
    padding: 40px;
  }
  .CoachesCarousal1 {
    width: 310px !important;
    .Bgdiv {
      width: 96%;
    }
    .BgdivContent {
      font-size: 15px;
      left: 43%;
      width: 62%;
    }
  }
  .Bgimg2 {
    top: 41px !important;
    left: -5% !important;
    width: 148px !important;
    height: 220px !important;
  }
  .Bgimg3 {
    left: -11% !important;
    top: 48px !important;
    width: 200px !important;
    height: 225px !important;
  }
  .Bgimg4 {
    left: -9% !important;
    top: 35px !important;
    width: 188px !important;
    height: 234px !important;
  }
}
@media (min-width: 360px) and (max-width: 379px) {
  .slick-initialized .slick-slide {
    display: block;
    display: flex;
    justify-content: center;
    padding: 40px;
  }
  .CoachesCarousal1 {
    width: 310px !important;
    .Bgdiv {
      width: 100% !important;
      left: 2% !important;
    }
    .BgdivContent {
      font-size: 14px;
      left: 43%;
      width: 54%;
    }
  }
  .Bgimg2 {
    top: 41px !important;
    left: 4% !important;
    width: 126px !important;
    height: 220px !important;
  }
  .Bgimg3 {
    left: -4% !important;
    top: 69px !important;
    width: 169px !important;
    height: 203px !important;
  }
  .Bgimg4 {
    left: -9% !important;
    top: 35px !important;
    width: 188px !important;
    height: 234px !important;
  }
}
// meet our coaches section
.CoachesDesktopDisplay{
  display: contents;
}
.CoachesMobileDisplay{
display: none;
}
@media (max-width:640px){
  .CoachesDesktopDisplay{
    display: none;
  }
  .CoachesMobileDisplay{
  display: contents;
  }
}
@media (min-width:480px) and (max-width:640px){
  .coachImg{
    height: 339px !important;
    width: 100%;
  }
  .coachesPara{
    font-size: 17px;
    margin-top: 10px;
  }
}
@media (max-width:479px){
  .CoachesMobileDisplay{
    .slick-prev{
      left: 2% !important;
      z-index: 9999;
    }
    .slick-next{
      right: 8% !important;
    }
    .slick-next:before, .slick-prev:before {
      font-size: 57px;
      
  }
  .slick-arrow{
    top: 37%; 
  }
  }
  .coachImg{
    height: 200px !important;
    width: 100%;
  }
  .coachesPara{
    // font-size: 15px;
    // margin-top: 10px;
    font-size: 15px;
    margin-top: 10px;
    width: 157%;
    position: relative;
    left: -50px;
  }
}
@media (max-width:640px){
  .ThreeDivSection{
    .fiftyOff{
      font-size: 20px !important;
    }
  }
}
