
.displayDesktop1 {
  display: block;
}

.displayMobile1 {
  display: none;
}
.fontHeaderNav {
  font-family: Poppins;
  font-size: 25px;
  font-weight: 700;
  color: rgb(185, 173, 173);
}

.NavibarHeader {
  background: #000000;
  z-index: 1;
  .fontBwNista20 {
    font-family: Poppins;
    font-size: 25px;
    font-weight: 700;
  }
  .logoImage {
    width: 180px;
    height: 150px;
  }
  .navbar-dark {
    .navbar-text {
      a:active,
      a:focus,
      a:hover {
        color: #00ffb4 !important;
      }
    }
  }
}
.dropdown-menu {
  background: #181414;
  .dropdown-item {
    color: #fff;

    :hover {
      color: black !important;
      background-color: rgb(230, 217, 217) !important;
    }
    :focus {
      color: black !important;
      background-color: rgb(230, 217, 217) !important;
    }
  }
}

.dropdown-menu {
  .dropdown-item:hover {
    color: black !important;
  }
}
.dropdown {
  .btn-secondary {
    background-color: #000000;
  }
}
@media (min-width: 993px) and (max-width: 1300px) {
  .NavibarHeader {
    .logoImage {
      width: 160px !important;
      height: 130px !important;
    }
    .fontBwNista20 {
      font-size: 23px !important;
    }
  }
}
@media (min-width: 640px) and (max-width: 992px) {
  .NavibarHeader {
    .logoImage {
      width: 100px !important;
      height: 70px !important;
    }
    .fontBwNista20 {
      font-size: 20px !important;
    }
  }
}
@media (min-width: 480px) and (max-width: 640px) {
  .NavibarHeader {
    .logoImage {
      width: 80px !important;
      height: 60px !important;
    }
    .fontBwNista20 {
      font-size: 20px !important;
    }
  }
}
@media (min-width: 360px) and (max-width: 480px) {
  .NavibarHeader {
    .logoImage {
      width: 60px !important;
      height: 45px !important;
    }
    .fontBwNista20 {
      font-size: 15px !important;
    }
  }
}
@media (max-width: 360px) {
  .NavibarHeader {
    .logoImage {
      width: 50px !important;
      height: 36px !important;
    }
    .fontBwNista20 {
      font-size: 15px !important;
    }
  }
}
@media (min-width:869px) and (max-width: 1224px) {
 .fontHeaderNav{
  font-size: 18px;
 }

}
@media (max-width: 868px) {
  .displayDesktop1 {
    display: none;
  }

  .displayMobile1 {
    display: block;
  }
}
