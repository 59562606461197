@import "./pages/Auth/auththeme.scss";
@import "./components/verticalMenu/verticalMenubar.scss";
@import "./pages/Tournament/TournamentTheme.scss";
@import "./pages/UserTournament/UserTournament.scss";
@import "./pages/Products/ProductTheme.scss";
@import "./pages/Game/gameTheme.scss";
@import "./pages/Mail/Mail.scss";
@import "./pages/Auth/Login/authLogin.scss";
@import "./pages/LeaderBoard/leaderTheme.scss";
@import "./components/Header/navbar.scss";
@import 'react-notifications-component/dist/theme.css';
@import "./components/common/commanTheme.scss";
@import "./pages/dashboard/dashboard.scss";
@import "./pages/Valorant/ValorantTheme.scss";
@import "./pages/CategoryPages/categorytheme.scss";
@import url('https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css');
@import url('https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css');
.mainHeaderPage{
  overflow-y: scroll;
}
.slick-next:before{
  content: url(./assets/images/icons8-chevron-right-50.png) !important;
}
.slick-prev:before {
  content: url(./assets/images/icons8-chevron-left-50.png) !important;
}
.slick-next,.slick-next:before,.slick-prev::before, .slick-prev {
  width: 50px !important;
  height: 50px !important;
  z-index: 9999;

}
.pageContent{
  background-color: #000000;
  // max-height:fit-content;
  // height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
}
.pageContent1{
  background-color: #000000;
  height:100vh;
  overflow-x: hidden;
  overflow-y: auto;
}
.z-index99{
  z-index: 99;
}
.form-control{
  color: #fff !important;
  
}

 @media (min-width:1301px){
  .GameRank_selector{
    margin-left: 20px;
    width: 100%;
  }
 }


.FontLoto155{
  font-family: "Poppins";
  font-weight: 500;
  font-style: normal;
  font-size: 15px;
}
.fontLoto{
  font-family: "Poppins";
  font-style: normal;
}

.fontLato355{
  // font-family: "Poppins";
  // font-weight: 500;
  font-style: normal;
  font-size: 35px;
 
}
.fontLato203{
  font-family: "Samsung Sans";
  font-weight: 300;
  font-style: normal;
  font-size: 20px;
}
.fontFuturaMedium157{
  font-family: "Poppins";
  font-weight: 700;
  font-style: normal;
  font-size: 15px;
}
.fontSamsung457{
  font-family: "Bw Nista Geometric DEMO Bold";
  font-weight: 700;
  font-style: normal;
  font-size: 45px;
}
.fontSamsung257{
  font-family: "Poppins";
  font-weight: 700;
  font-style: normal;
  font-size: 25px;
}
.fontGeomatrixLight{
  font-family: "Poppins";
}
.fontRoboto577 {
  font-family: "Bw Nista Geometric DEMO Bold";
  font-weight: 700;
  font-style: normal;
  font-size: 57px;
}
.fontRoboto474 {
  font-family: "Poppins" !important;
  font-weight: 400;
  font-style: normal;
  font-size: 47px !important;
}
.fontRoboto357 {
  font-family: "Poppins" !important;
  font-weight: 700;
  font-style: normal;
  font-size: 35px !important;
}
.fontRoboto257 {
  font-family: "Poppins";
  font-weight: 700;
  font-style: normal;
  font-size: 25px;
}
.fontRoboto254 {
  font-family: "Poppins" !important;
  font-weight: 400;
  font-style: normal;
  font-size: 25px !important;
}
.fontRoboto207 {
  font-family: "Poppins";
  font-weight: 700;
  font-style: normal;
  font-size: 20px;
}
.fontRoboto204 {
  font-family: "Poppins";
  font-weight: 400;
  font-style: normal;
  font-size: 20px;
}
.fontRoboto157 {
  font-family: "Poppins";
  font-weight: 700;
  font-style: normal;
  font-size: 15px;
}
.fontRoboto154 {
  font-family: "Poppins";
  font-weight: 400;
  font-style: normal;
  font-size: 15px;
}
.fontRoboto147 {
  // font-family: Poppins;
  font-weight: 700;
  font-style: normal;
  font-size: 14px;
}
.fontRoboto144 {
  font-family: "Poppins";
  font-weight: 400;
  font-style: normal;
  font-size: 14px;
}
.fontRoboto137 {
  font-family: "Poppins";
  font-weight: 700;
  font-style: normal;
  font-size: 13px;
}
.fontRoboto134 {
  font-family: "Poppins";
  font-weight: 400;
  font-style: normal;
  font-size: 13px;
}
.fontRoboto127 {
  font-family: "Poppins";
  font-weight: 700;
  font-style: normal;
  font-size: 12px;
}
.fontRoboto124 {
  font-family: "Poppins";
  font-weight: 400;
  font-style: normal;
  font-size: 12px;
}
a{
  text-decoration: none !important;
  color: unset !important;
}
.page-contentBg{
  background-color: #000000;
}
.textClrF3F3F3{
  color:#F3F3F3;
}
a:hover{
  color: unset !important;
}
.alignItem-middle{
  align-items: center;
  display: flex !important;
  justify-content: center !important;
}
.error {
  color: hsla(0, 100%, 50%, 0.589);
}
.container, 
.container-lg, 
.container-md, 
.container-sm, 
.container-xl {
  max-width: 95% !important;
}

.pagination{
  ul{
    li{
      button{
        background-color: white;
        color: #000000;

      }
      button:hover{
        background-color: white;
        color: #000000;
        
      }
   
    }
  }
}
.IndexDatatableNeoCol{
  // text-transform: capitalize;
  font-family: Poppins !important;
  font-size: 22px;
}
.dropdown {
  z-index: 9999;
  .dropdown-content {
    right: 40px;
    bottom: 0px;
    position: absolute;
    font-family: Poppins;
  }
}
//status text
.actionText {
  font-weight: 700;
  font-size: 13px;
}

//action dropdown
.dropbtn {
  color: white;
  padding-left: 16px;
  padding-right: 16px;
  font-size: 16px;
  border: none;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: fixed;
  background-color: #f1f1f1;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content div {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content div:hover {
  background-color: #ddd;
}

.dropdown:hover .dropdown-content {
  display: block;
}
.dropdown .dropdown-content {
  right: 10px;
  bottom: 0px;
  position: absolute;
  cursor: pointer;
}
.cursor-pointer{
  cursor: pointer;
}
.NoDataDiv{
  background: #0d0d0d;
  padding: 100px;
  width: 98%;
  font-size: 20px;
  font-family: 'Poppins';
  color: #fff;
}
.NoDataDiv1{
  padding: 100px;
  width: 98%;
  font-size: 20px;
  // font-family: 'Poppins';
  color: #fff;
}
.activeUsersCard{
  width: 300px;
  height: 200px;
  background-color: #191717 !important;
  color: #fff !important;
  font-size: 20px;
  div{
    padding: 20px;
  }
  .NumberDiv{
    font-size: 35px;
  }
}
.form{
  background-color: #131313;
}

.UserCard{
  padding: 30px !important;
  background-color: #131212 !important;
  span{
    color: #fff !important;
  }
  height: 200px;
}
.UserCard1{
  padding: 30px !important;
  background-color: #131212 !important;
  span{
    color: #fff !important;
  }
  height: 300px;
}
.addButton{
  button{
    background-color: #131212;
    padding: 10px 30px;
    margin-top: 36px;
  }

}
.submitButton{
  background-color: rgb(44, 41, 131);
  color: #fff;
  float: right;
  padding: 10px 20px;
  margin-right: 30px;
}
input {
  outline: 0;
  background: #232323 !important;
  color: #a99898 !important;
  width: 100%;
  border: 0;
  border-radius: 5px;
  margin: 0 0 15px;
  padding: 15px;
  box-sizing: border-box;
  font-size: 14px;
  font-family: "Lato", sans-serif;
}
.rs-picker-menu{
  z-index: 9999 !important;
}
.rs-picker-toggle .rs-btn .rs-btn-default
{background-color: #232323 !important;
}
.rs-picker-default .rs-picker-toggle.rs-btn {
 
  background-color: #232323 !important;
}
.modal{
  top: 10px !important;
  // overflow-y: hidden !important;
}
@media (max-width:640px){
.modal{
  top: 120px !important;
}}
.SearchIcon{
  position: relative;
    top: 10px;
    right: 39px;
}
.closeIconTou{
  position: relative;
  left: 360%;
}
.alignItemPopUpLogin {
  position: relative;
  /* right: -122px; */
  top: -10px;
}
.dropdown{
  z-index: 999 !important;
}

.RequiredField{
  color: #900909;
  font-size: 20px;
  padding-left: 30px;
  padding-top: 5px;
}
.react-select__placeholder{
  margin-left: 10px !important;
}
.react-select__input-container {
text-align: left !important;
color: #fff;
// text-transform: capitalize;
}
.noListingPage{
  font-family: Poppins;
  font-size: 20px;
  width: 100%;
  height: 400px;
  background-color: #090808;
  justify-content: center;
  text-align: center;
  color: #fff;
}
.pageContentChild{
  display: flex;
}
@media (max-width:878px){
  .pageContentChild{
    display: block;
  }
}
@media (max-width:1024px) {
  .IndexDataTableNeoParent {
    display: block;
    width: 100%;
    overflow: auto;

    .scroollDataTableNeo {
      // display: contents;
      white-space: initial;
      // overflow-y: hidden;

      width: 100%;
      display: table;
      overflow: auto;
      flex-wrap:wrap !important;
      // padding: 0px 15px;

      .CardDateTable {
        width: max-content;
        min-width: 150%;
        .IndexDatatableNeoRowScroll,
        .IndexDatatableNeoRow {
          display: flex;
          white-space: wrap;
          overflow-y: visible;
          flex-wrap: wrap !important;
          width: 100%;
          max-width: -webkit-fill-available;

          .IndexDatatableNeoCol {
            text-align: left;
            padding-left: 100px;
          }
        }
      }
    }
  }
}
// table
.TeamLabel{
  margin-left: 60px;
  // text-transform: capitalize;
  label{
    font-size: 20px;
    font-weight: 1000;
    padding-bottom: 10px;
  }
  span{
    margin-left: 10px;
    font-size: 20px;
  }
}
@media (max-width:878px){
  .TeamLabel{
    margin-left: 60px;
    label{
      font-size: 15px;
   
    }
    span{
      font-size: 15px;
    }
  }
}
.tableStyle{
  height: 600px;
  overflow-y: auto;
}
.TeamHeader1{
  background: #fff;
    color: #000;
    margin: 16px 16px 3px 12px;
    font-size: 23px !important;
    padding: 5px;
    align-items: center ;

}
.TeamBody{
  align-items: center;
}
.teamBodyOdd{
  height: 50px;
  color: #fff;
  font-size: 20px;
  background-color: #abc7ab;
  // text-transform: capitalize;
  font-weight: 900;
  margin-left: 11px;
  margin-right: 15px;
  align-items: center;
}
.teamBodyEven{
  height: 50px;
  color: #fff;
  font-size: 20px;
  opacity: 0.8;
  // background-color: rgba(223, 219, 221, 0.9);
  // text-transform: capitalize;
  font-weight: 900;
  margin-left: 11px;
  margin-right: 15px;
  align-items: center;
}


@media (max-width:380px){
  .tableHeader{
    // width: 300px;
    overflow: auto;
  }
  .TeamHeader, .TeamBody,.teamBodyEven,.teamBodyOdd,.TeamHeader1{
    width: 200%;
    font-size: 14px !important;
  }
  .TeamBody{
    img{
      width: 50px !important;
      height: 50px !important;
    }
    .TeamImag{
      img{
        position: absolute;
        top: 4px !important;
      }
    }
  }
}
@media (min-width:380px) and (max-width:480px){
  .tableHeader{
    // width: 380px;
    overflow: auto;
  }
  .TeamHeader, .TeamBody,.teamBodyEven,.teamBodyOdd,.TeamHeader1{
    width: 200%;
    font-size: 14px !important;
  }
  .TeamBody{
    img{
      width: 50px !important;
      height: 50px !important;
    }
    .TeamImag{
      img{
        position: absolute;
        top: 4px !important;
      }
    }
  }
}
@media (min-width:480px) and (max-width:640px){
  .tableHeader{
    width: auto;
    overflow: auto;
  }
  .TeamHeader, .TeamBody,.teamBodyEven,.teamBodyOdd,.TeamHeader1{
    width: 150%;
    font-size: 14px !important;
  }
  .TeamBody{
    img{
      width: 50px !important;
      height: 50px !important;
    }
    .TeamImag{
      img{
        position: absolute;
        top: 4px !important;
      }
    }
  }
}
@media (min-width:640px) and (max-width:1080px){
  .tableHeader{
    width: auto;
    // overflow: auto;
  }
  .TeamHeader, .TeamBody,.teamBodyEven,.teamBodyOdd,.TeamHeader1{
    width: 90%;
    font-size: 14px !important;
  }
  .TeamBody{
    img{
      width: 50px !important;
      height: 50px !important;
    }
    .TeamImag{
      img{
        position: absolute;
        top: 4px !important;
      }
    }
  }
}
// leaderboard modal
.leaderboardModal{
  .modal-body{
    min-width: 1300px;
    left: -57%;
  }
  .ModalHadingName{
    display: flex;
    justify-content: space-between;
    padding:10px;
    span{
      font-weight: 1000;
      font-size: 30px;
      color: #fff;
    }
  }
}
@media (min-width:1025px) and (max-width:1400px){
  .leaderboardModal{
    .modal-body{
      min-width: 1000px;
      left: -30%;
    }
  }
}
@media (max-width:1024px){
  .leaderboardModal{
    .modal-body{
      min-width: unset;
      left: unset;
    }
  }
}
.tableHeader{
  height: 500px;
  overflow: auto;
}
.NoTaggedVideos{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  h3{
    color: #fff !important;
    font-size: 25px !important;

  }
  span{
    color: #fff !important;
    font-size: 25px !important;
  }
}
.iframe{
  height: 100% !important;
}
// Modals

.WhatsAppBulkModal {
  margin-bottom: 50px !important;

  .modal-content {
    padding: 40px;
    background-color: rgb(5 9 36);
  }
  .modal-header{
    border: none !important;
    color: #fff !important;
  }
  .modal-title {
    width: 100%;
  }
  .Heading {
    display: flex;
    justify-content: space-between;
    // margin: 0px 20px;
    svg {
      margin-top: 15px;
      cursor: pointer;
    }
  }
  
}
.WhatsAppBulk{
  .imageDiv {
    position: relative;
    width: 100px;
    height: 100px;
    background: #1e1b1b;
    border: 0.5px dashed #c6c6c6;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
      width: 100%;
      cursor: pointer;
    }
    .clickText {
      position: relative;
      left: 25px;
    }
  }
  .form1 {
    textarea{
      width: 100%;
      height: 300px;
      background-color: #1e1b1b;
      color: #fff;
      // text-transform: capitalize;
      overflow: auto;
      padding: 10px;
      font-size: 15px;
    }
    label {
      font-size: 20px;
      color: #fff;
      font-weight: 400;
      span {
        color: red;
      }
    }
    .formDiv {
      margin-top: 40px;
      margin-bottom: 40px;
    }
    .formDiv1 {
      margin-top: 20px;
    }
    .form-control {
      padding: 10px;
      height: 50px;
      // text-transform: capitalize;
      cursor: pointer;
    }
    .React_selector {
      input {
        height: 40px;
        cursor: pointer;
      }
    }
  }
  .artButton {
    padding: 10px 20px;
    background: #1a1a1a;
    margin-top: 40px;
    width: 50%;
    height: 50px;
  }
}
@media (min-width: 641px) and (max-width: 768px) {
  .MVpDiv {
    .slick-prev {
      left: 10% !important;
    }
    .slick-next {
      right: 17% !important;
    }
    // .slick-next,
    // .slick-prev {
    //   z-index: 99999;
    //   top: 32%;
    // }
    .slick-next:before,
    .slick-prev:before {
      font-size: 50px;
    }
  }
  
}
@media (min-width: 481px) and (max-width: 640px) {
  .MVpDiv {
    .slick-prev {
      left: 10% !important;
    }
    .slick-next {
      right: 15% !important;
    }
    // .slick-next,
    // .slick-prev {
    //   z-index: 99999;
    //   top: 32%;
    // }
    .slick-next:before,
    .slick-prev:before {
      font-size: 50px;
    }
  }
  
}
@media (max-width: 480px) {
  .MVpDiv {
    .slick-prev {
      left: 10% !important;
    }
    .slick-next {
      right: 15% !important;
    }
    // .slick-next,
    // .slick-prev {
    //   z-index: 99999;
    //   top: 32%;
    // }
    .slick-next:before,
    .slick-prev:before {
      font-size: 50px;
    }
  }
  
}

.NoteText{
  span{
    color: rgb(6, 2, 96);
    font-size: 16px;
  }
  font-size: 16px;
  font-style: italic;
  color: rgb(6, 2, 96) !important;
}
.NoteText1{
  span{
    color: rgb(212, 57, 40);
    font-size: 16px;
  }
  font-size: 18px;
  font-style: italic;
  color: rgb(212, 52, 40) !important;
}
.mediaUrl {
  height: 70px;
  background-color: #000;
  border: 1px solid #191515;
  width: 96%;
  margin-bottom: 10px;
  font-size: 16px;
  padding-left: 10px;
}
