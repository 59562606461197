.sideBar {
    width: 240px;
    background-color: #1c1c1d;
    padding: 10px;
    height: 100vh;
    margin: 10px;

    div {
        font-size: 21px;
        color: #fff;
        padding: 10px;
    }
}

.displayDesktop {
    display: block;
}

.displayMobile {
    display: none;
}

@media (max-width:878px) {
    .sideBar{
        width: 70px;
        background: none !important;
        height: 44px !important;
    }
    .displayDesktop {
        display: none;
    }

    .displayMobile {
        display: block;
    }
}