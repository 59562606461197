.editIcon,.deleteIcon{
    font-size: 20px !important;
    margin: 2px 13px;
}
.deleteIcon{
    color: red;
}
.AddGamePage{
    .form{
        background-color: #0e0c0c !important;
    }
}
.addGameButton{
    background-color: #0e0c0c;
    color: #fff;
    border: 0.5px solid rgb(95, 83, 83);
    padding: 15px 60px !important;
}