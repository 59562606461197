.CardMail {
  height: 800px !important;
  background-color: black !important;
}
.subjectInput {
  margin: 20px 0px;
  border: 1px solid #191515;
  width: 95%;
}
.subjectInput,
.bodyInput {
  input {
    outline: none !important;
    // border-bottom: 0.2px solid #323131 !important;
    width: 95% !important;
    margin: 10px;
    border: none;
    background-color: black !important;
  }
  textarea:focus,
  input:focus {
    outline: none !important;
  }
}
.bodyInput {
  input {
    background-color: black !important;
    height: 200px !important;
  }
}
.sendButton {
  background-color: #2e2eb5;
  color: #fff;
  padding: 10px 20px;
  border-radius: 5px;
  float: right;
  margin-right: 72px;
  width: 15%;
  text-transform: capitalize;
  font-size: 20px;
}
.emailSpan {
  background-color: #443b3b;
  padding: 5px 34px;
  color: #fff;
  border-radius: 10px;
  margin: 0px 3px;
}
.bodyInput {
  textarea {
    background-color: #000;
    height: 300px;
    width: 95%;
    color: #fff;
    padding: 10px;
    font-size: 20px;
    border: 1px solid #191515;
  }
}
textarea {
  resize: none;
}
.selectArea {
  background-color: #000;
  height: 150px;
  width: 95%;
  color: rgb(85, 77, 77);
  padding: 10px;
  font-size: 15px;
  border: 1px solid #191515;
  overflow: auto;
  .phoneBox,
  .emailBox {
    display: flex;
    flex-wrap: wrap;
  }
}
// .CardMail{
//   input,textarea{
//     text-transform: capitalize;
//   }
// }

