@font-face {
  font-family: futuraFont;
  src: url(../../../assets/fonts/Futura/FuturaLT-CondensedBold.ttf);
}
.mt-68 {
  margin-top: 68px;
}
.displayDesktopFooter {
  display: block;
}

.displayMobileFooter {
  display: none;
}
.googleIcon{
  font-size: 44px;
  margin-top: 17px;
  cursor: pointer;
  color: #fff;
}
.LoginPage {
  position: relative;

  .fontBecomeOGGG {
    font-family: futuraFont;
    font-size: 83px;
    font-weight: 1000;
    text-transform: uppercase;
  }

  .OGGGDiv {
    .spanHeaderImageFontStyles {
      line-height: 30px;
      text-align: center;
      color: #00ffb4;
    }

    .HeaderImage {
      width: 60vw;
      // height: 598px;
      object-fit: cover;
      // z-index: 999;
      margin-top: -76px;
      position: relative;
      left: -5%;
    }

    .HeaderImgTextDisplayPos {
      position: relative;
      bottom: 53%;
      left: -1%;
    }

    .fontBecomeProButton {
      font-family: Poppins !important;
      font-size: 40px !important;
    }

    .spanHeaderButton {
      color: #000000;
      // border-radius: 20px !important;
      padding: 0px 160px !important;
      margin: 20px !important;
      margin-top: 35px !important;
      background: #00ffb4;
      border-radius: 72.5px;
      text-transform: none;
    }

    .spanHeaderButton:hover {
      background-color: #00ffb4 !important;
    }

    .spanDiv {
      margin: 100px 0px 50px 0px;
    }
  }

  .ChooseGameDiv {
    display: flex;
    justify-content: center;
    align-items: normal;
    width: 70%;
    position: relative;
    left: 15%;

    .marginDiv {
      margin-top: 20px;
    }

    .textAlignStylesV {
      text-align: center;
      padding-right: 73px;
      padding-left: 100px;
      color: #fff !important;
    }

    .textAlignStylesB {
      text-align: center;
      margin-left: 115px;
      margin-right: 59px;
      color: #fff !important;

    }

    .fontMvpbW {
      font-family: Poppins;
      font-size: 30px;
    }

    .fontChooseGame {
      font-family: futuraFont;
      font-size: 83px;
      font-weight: 1000;
      text-transform: uppercase;
    }

    .fontVolorant,
    .fontBGMI {
      font-family: futuraFont;
      font-size: 30px;
    }

    .fontContentStyle {
      font-family: Poppins !important;
      font-size: 19px !important;
    }

    .imageCard {
      height: 720px !important;
      background-color: #000 !important;
      border-radius: 18%;

      img,
      svg {
        position: relative;
        left: 18%;
        width: 69%;
        height: 396px;
        vertical-align: middle;
        border-radius: 25px;
        margin-top: 29px;
      }
    }
  }

  .LegendDiv {
    width: 94%;
    position: relative;
    left: 3%;

    

    .spanDiv {
      margin: 50px 0px 50px 0px;
    }

    .fontLegend {
      margin-top: 200px;
      margin-bottom: 20px;
      font-family: futuraFont;
      font-size: 83px;
      font-weight: 1000;
      text-transform: uppercase;
    }

    .fontTookKitMaster {
      font-family: Poppins;
      font-size: 35px;
    }
  }

  .fontContentVideo {
    font-family: poppins;
    font-size: 30px;
    line-height: 30px;
    padding-bottom: 20px;
    // width: 100px;
  }

  .fontContentText {
    font-family: Poppins;
    font-size: 18px;
    line-height: 20px;
  }

  .fontContentText1 {
    font-family: Poppins;
    font-size: 25px;
  }

  .WordsDiv {
    width: 84%;
    position: relative;
    left: 8%;
    .fontWord {
      font-family: futuraFont;
      font-size: 83px;
      font-weight: 1000;
      text-transform: uppercase;
    }
  }

  .joinButton {
    border-radius: 74px !important;
    padding: 0px 79px !important;
    margin: 10px !important;
    background-color: #00ffb4 !important;
    color: #000000 !important;
    font-family: poppins;
    font-style: normal;
    font-weight: 1000 !important;
    font-size: 31px !important;
    margin-bottom: 43px !important;
    // text-transform: capitalize;
  }

  .spanDiv {
    margin: 107px 0px -1px 0px;
  }

  .getCoachDiv {
    width: 84%;
    position: relative;
    left: 8%;

    .fontCoach {
      font-family: futuraFont;
      font-size: 40px;
    }

    .imageCardCoaches {
      height: 610px;
      background-color: #000 !important;
      border-radius: 18%;

      .imagePrathik {
        left: 30%;
      }

      img,
      svg {
        position: relative;
        width: 60%;
        height: 370px;
        vertical-align: middle;
        border-radius: 11px;
        margin-top: 20px;
      }

      .textNames {
        color: #00ffb4;
      }

      .namePosition {
        display: block;
        justify-content: center;
        position: relative;
        // left: 87%;
      }

      .namePosition1 {
        display: block;
        justify-content: center;
        position: relative;
        // left: 76%;
      }

      .namePrathika {
        //   line-height: 46px !important;
        // width: 500px !important;
        // padding-left: 160px;
        padding-bottom: 0px !important;
        // padding-top: 10px;
        font-family: futuraFont;
        font-style: normal;
        font-weight: 700;
        font-size: 35px;
        // line-height: 45px;
        text-transform: uppercase;
        color: #00ffb4;
      }

      .nameBrad {
        // width: 500px !important;
        // padding-left: 200px;
        padding-bottom: 0px !important;
        // padding-top: 10px;
        font-family: futuraFont;
        font-style: normal;
        font-weight: 700;
        font-size: 35px;
        // line-height: 45px;
        text-transform: uppercase;
        color: #00ffb4;
      }

      .nameGame {
        font-family: poppins;
        font-style: normal;
        font-size: 30px;
        //   line-height: 48px;
        color: #f3f3f3;
        // padding-left: 22px;
        margin-bottom: 3px !important;
      }
    }
  }

  .everyDiv {
    width: 80%;
    position: relative;
    left: 10%;

    .fontEvery {
      font-family: futuraFont;
      font-size: 83px;
      font-weight: 1000;
      text-transform: uppercase;
    }

    .fontTookKitMaster {
      font-family: Poppins;
      font-size: 35px;
    }

    .fontEveryHeader {
      font-family: poppins;
      font-size: 30px;
      line-height: 30px;
    }

    .fontEveryContent {
      font-family: Poppins;
      font-size: 19px;
      padding-top: 23px;
      line-height: 23px;
    }

    .CardStylesGGOG {
      width: 80%;
      height: 319px;
      background-color: #151515 !important;
      border-radius: 10px !important;
      display: flex;
      justify-content: center;
      // align-items: center;
    }
  }

  .CardStylesCarousel {
    height: 284px;
    background: linear-gradient(90deg, #0e0e0e 0% 100%);
  }

  .fontComingSoon {
    font-family: futuraFont;
    font-size: 40px;
    font-weight: 900;
  }

  .fontExclusive {
    font-family: Poppins;
    font-size: 25px;
  }

  .CommingSoonDiv {
    margin: 100px 0px;
  }

  .footerDiv {
    width: 90%;
    position: relative;
    left: 5%;

    .fontFooterLink {
      font-family: Poppins;
      font-size: 25px;
      font-weight: 1000;
      text-transform: capitalize;
    }

    .fontFollow {
      font-family: Poppins;
      font-size: 27px;
      font-weight: 900;
    }

    .fontEmail {
      font-family: Poppins;
      font-size: 19px;
    }
  }

  .video-section {
    // margin-bottom: 20px;
    width: 100% !important;
    min-height: 100%;

  }
}

// media quarie for GG for EVery OG Div
@media(min-width:600px){
  .TextPadding {
    padding-top: 30px !important;
  }
}
@media (min-width: 1200px) and (max-width: 1599px) {
  .fontBecomeOGGG,
  .fontChooseGame,
  .fontLegend,
  .fontWord,
  .fontEvery {
    font-size: 70px !important;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .fontBecomeOGGG,
  .fontChooseGame,
  .fontLegend,
  .fontWord,
  .fontEvery {
    font-size: 60px !important;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .fontBecomeOGGG,
  .fontChooseGame,
  .fontLegend,
  .fontWord,
  .fontEvery {
    font-size: 60px !important;
  }
}
@media (min-width: 640px) and (max-width: 767px) {
  .fontBecomeOGGG,
  .fontChooseGame,
  .fontLegend,
  .fontWord,
  .fontEvery {
    font-size: 50px !important;
  }
}
@media (min-width: 480px) and (max-width: 639px) {
  .fontBecomeOGGG,
  .fontChooseGame,
  .fontLegend,
  .fontWord,
  .fontEvery {
    font-size: 37px !important;
  }
}
@media (min-width: 380px) and (max-width: 479px) {
  .fontBecomeOGGG,
  .fontChooseGame,
  .fontLegend,
  .fontWord,
  .fontEvery {
    font-size: 34px !important;
  }
}
@media (max-width: 379px) {
  .fontBecomeOGGG,
  .fontChooseGame,
  .fontLegend,
  .fontWord,
  .fontEvery {
    font-size: 30px !important;
  }
  .fontLegend{
    margin-top: 160px !important;
  }
}

@media (min-width: 1681px) and (max-width: 2000px) {
  .LoginPage {
    .everyDiv {
      width: 80%;
      left: 10%;

      .fontTookKitMaster {
        font-size: 35px;
      }

      .fontEveryHeader {
        font-size: 32px;
      }

      .fontEveryContent {
        font-size: 25px !important;
        line-height: 27px !important;
      }
    }

    .CardStylesGGOG {
      width: 70%;
      height: 400px !important;
    }
  }
}

@media (min-width: 1537px) and (max-width: 1680px) {
  .LoginPage {
    .everyDiv {
      width: 80%;
      left: 10%;

      .fontTookKitMaster {
        font-size: 35px;
      }

      .fontEveryHeader {
        font-size: 32px;
      }

      .fontEveryContent {
        font-size: 22px;
      }
    }

    .CardStylesGGOG {
      width: 80%;
      height: 380px !important;
    }
  }
}

@media (min-width: 1301px) and (max-width: 1536px) {
  .LoginPage {
    .everyDiv {
      width: 80%;
      left: 10%;

      .fontTookKitMaster {
        font-size: 35px;
      }

      .fontEveryHeader {
        font-size: 32px;
      }

      .fontEveryContent {
        font-size: 22px;
      }
    }

    .CardStylesGGOG {
      width: 90% !important;
      height: 370px !important;

      .widthName1 {
        width: 90% !important;
        padding-left: 5%;
        padding-right: 5%;
      }
    }
  }
}

@media (min-width: 1224px) and (max-width: 1300px) {
  .LoginPage {
    .everyDiv {
      width: 80%;
      left: 10%;

      .fontTookKitMaster {
        font-size: 35px;
      }

      .fontEveryHeader {
        font-size: 32px;
      }

      .fontEveryContent {
        font-size: 20px;
      }
    }

    .CardStylesGGOG {
      width: 90% !important;
      height: 370px !important;

      .widthName1 {
        width: 80% !important;
        padding-right: 10%;
      }
    }

    // .video-section {
    //   width: 100% !important;
    //   height: 250px !important;
    //   margin-bottom: 40px;
    // }

    // .namePrathika {
    //   padding-left: 20px !important;
    //   width: 600px;

    //   .nameGame {
    //     padding-right: 60px !important;
    //   }
    // }

    // .nameBrad {
    //   padding-left: 120px !important;

    //   .nameGame {
    //     padding-left: 40px;

    //   }
    // }
  }
}

@media (min-width: 1024px) and (max-width: 1223px) {
  .LoginPage {
    .everyDiv {
      width: 80%;
      left: 10%;

      .fontTookKitMaster {
        font-size: 35px;
      }

      .fontEveryHeader {
        font-size: 28px;
      }

      .fontEveryContent {
        font-size: 19px;
      }
    }

    .CardStylesGGOG {
      width: 90% !important;
      height: 350px !important;

      .widthName1 {
        width: 90% !important;
        padding-left: 5%;
        padding-right: 5%;
      }
    }

    // .video-section {
    //   width: 270px !important;
    //   height: 250px !important;
    //   margin-bottom: 40px;
    // }

    .namePrathika {
      padding-left: 40px;
    }
  }
}

@media (min-width: 898px) and (max-width: 1023px) {
  .LoginPage {
    .everyDiv {
      width: 90%;
      left: 5%;

      .fontTookKitMaster {
        font-size: 30px;
      }

      .fontEveryHeader {
        font-size: 25px;
      }

      .fontEveryContent {
        font-size: 17px;
      }
    }

    .CardStylesGGOG {
      width: 100% !important;
      height: 370px !important;

      .widthName1 {
        width: 80% !important;
        padding-right: 10%;
      }
    }
  }
}

@media (min-width: 700px) and (max-width: 897px) {
  .LoginPage {
    .everyDiv {
      width: 80%;
      left: 10%;

      .fontTookKitMaster {
        font-size: 25px;
      }

      .fontEveryHeader {
        font-size: 28px;
      }

      .fontEveryContent {
        font-size: 23px;
      }
    }

    .CardStylesGGOG {
      width: 80% !important;
      position: relative;
      left: 10%;
      height: auto !important;
      padding-bottom: 30px;
      margin-bottom: 20px;

      .widthName1 {
        width: 80% !important;
        padding-right: 10%;
        padding-left: 10%;
      }
    }
  }
}

@media (min-width: 621px) and (max-width: 699px) {
  .LoginPage {
    .everyDiv {
      width: 80%;
      left: 10%;

      .fontTookKitMaster {
        font-size: 22px;
      }

      .fontEveryHeader {
        font-size: 25px;
      }

      .fontEveryContent {
        font-size: 20px;
      }
    }

    .CardStylesGGOG {
      width: 80% !important;
      position: relative;
      left: 10%;
      height: auto !important;
      padding-bottom: 30px;
      margin-bottom: 20px;

      .widthName1 {
        width: 80% !important;
        padding-right: 10%;
        padding-left: 5%;
      }
    }

    // .video-section {
    //   width: 260px !important;
    // }
  }
}

@media (min-width: 480px) and (max-width: 620px) {
  .LoginPage {
    .everyDiv {
      width: 80%;
      left: 10%;

      .fontTookKitMaster {
        font-size: 17px;
      }

      .fontEveryHeader {
        font-size: 20px;
      }

      .fontEveryContent {
        font-size: 17px;
      }
    }

    .CardStylesGGOG {
      width: 80% !important;
      position: relative;
      left: 10%;
      height: auto !important;
      padding-bottom: 30px;
      margin-bottom: 20px;

      .widthName1 {
        width: 80% !important;
        padding-right: 8%;
        padding-left: 8%;
      }
    }

    // .video-section {
    //   width: 300px !important;
    //   height: 240px !important;
    // }
  }
}

@media (min-width: 390px) and (max-width: 479px) {
  .LoginPage {
    .everyDiv {
      width: 90%;
      left: 5%;

      .fontTookKitMaster {
        font-size: 15px;
      }

      .fontEveryHeader {
        font-size: 20px;
      }

      .fontEveryContent {
        font-size: 17px;
      }
    }

    .CardStylesGGOG {
      width: 90% !important;
      position: relative;
      left: 5%;
      height: auto !important;
      margin-bottom: 20px;
      padding-bottom: 30px;

      .widthName1 {
        width: 90% !important;
        padding-right: 10%;
        padding-left: 5%;
      }
    }
  }
}

@media (min-width: 310px) and (max-width: 389px) {
  .LoginPage {
    .everyDiv {
      width: 90%;
      left: 5%;

      .fontTookKitMaster {
        font-size: 13px;
      }

      .fontEveryHeader {
        font-size: 18px;
      }

      .fontEveryContent {
        font-size: 15px;
      }
    }

    .CardStylesGGOG {
      width: 90% !important;
      position: relative;
      left: 5%;
      height: auto!important;
      margin-bottom: 20px;
      padding-bottom: 30px;

      .widthName1 {
        width: 90% !important;
        padding-right: 10%;
        padding-left: 5%;
      }
    }
  }
}

@media (max-width: 309px) {
  .LoginPage {
    .everyDiv {
      width: 100%;
      left: 0%;

      .fontTookKitMaster {
        font-size: 12px;
      }

      .fontEveryHeader {
        font-size: 15px;
      }

      .fontEveryContent {
        font-size: 13px;
      }
    }

    .CardStylesGGOG {
      width: 100% !important;
      height: auto !important;
      padding-bottom: 30px;
      margin-bottom: 20px;

      .widthName1 {
        margin-top: 20px !important;
        width: 90% !important;
        padding-right: 8%;
        padding-left: 8%;
      }
    }
  }
}

@media (max-width: 898px) {
  .LoginPage {
    .CommingSoonDiv {
      margin: 44px 0px !important;
    }
  }
}

// media querie for Comming soon and footer
@media (min-width: 1336px) and (max-width: 2000px) {
  .LoginPage {
    .fontComingSoon {
      font-size: 83px;
    }

    .fontExclusive {
      font-size: 25px;
    }

    .footerDiv {
      width: 80%;
      position: relative;
      left: 10%;

      .fontFooterLink {
        font-size: 25px;
        font-weight: 1000;
      }

      .fontFollow {
        font-size: 27px;
      }

      .fontEmail {
        font-size: 23px;
      }
    }
  }
}

@media (min-width: 993px) and (max-width: 1335px) {
  .LoginPage {
    .fontComingSoon {
      font-size: 30px;
    }

    .fontExclusive {
      font-size: 25px;
    }

    .footerDiv {
      width: 80%;
      position: relative;
      left: 10%;

      .fontFooterLink {
        font-size: 20px !important;
      }

      .fontFollow {
        font-size: 27px;
      }

      .fontEmail {
        font-size: 20px;
      }
    }
  }
}

@media (min-width: 600px) and (max-width: 993px) {
  .LoginPage {
    .faIconsStyles {
      font-size: 28px;
    }

    .fontComingSoon {
      font-size: 30px;
    }

    .fontExclusive {
      font-size: 21px;
    }

    .footerDiv {
      width: 90%;
      position: relative;
      left: 5%;

      .fontFooterLink {
        font-size: 15px !important;
      }

      .fontFollow {
        font-size: 25px;
      }

      .fontEmail {
        font-size: 13px;
      }
    }
  }
}

@media (min-width: 480px) and (max-width: 599px) {
  .LoginPage {
    .faIconsStyles {
      font-size: 25px;
    }

    .fontComingSoon {
      font-size: 25px;
    }

    .fontExclusive {
      font-size: 16px;
    }

    .footerDiv {
      width: 90%;
      position: relative;
      left: 5%;

      .fontFooterLink {
        font-size: 11px !important;
      }

      .fontFollow {
        font-size: 15px;
      }

      .fontEmail {
        font-size: 10px;
      }
    }
  }
}

@media (min-width: 420px) and (max-width: 479px) {
  .LoginPage {
    .faIconsStyles {
      font-size: 20px;
    }

    .fontComingSoon {
      font-size: 23px;
    }

    .fontExclusive {
      font-size: 16px;
    }

    .footerDiv {
      width: 90%;
      position: relative;
      left: 5%;

      .fontFooterLink {
        font-size: 8px !important;
      }

      .fontFollow {
        font-size: 13px;
      }

      .fontEmail {
        font-size: 7px;
      }
    }
  }
}

@media (max-width: 419px) {
  .displayDesktopFooter {
    display: none;
  }

  .faIconsStyles {
    font-size: 20px !important;
  }

  .fontFollow {
    font-size: 13px !important;
  }

  .fontComingSoon {
    font-size: 18px !important;
  }

  .fontExclusive {
    font-size: 12px !important;
  }

  .displayMobileFooter {
    display: block;
  }

  .fontFooterLink {
    font-size: 12px !important;
  }

  .fontEmail {
    font-size: 10px !important;
    width: 80%;
    text-align: center;
  }
}

// media querie for Legend
@media (min-width: 1681px) and (max-width: 2000px) {
  .LegendDiv {
    width: 80%;
    position: relative;
    left: 10%;

    .spanDiv {
      margin: 50px 0px 50px 0px;
    }

    .fontTookKitMaster {
      font-size: 35px;
    }

    .fontContentText {
      font-size: 20px !important;
    }

    .CarosuelVideoStyle1 {
      width: 97% !important;
      height: 208px !important;
    }

    .CardStyles {
      height: 246px !important;
    }
  }
}

@media (min-width: 1337px) and (max-width: 1680px) {
  .LegendDiv {
    width: 80%;
    position: relative;
    left: 10%;

    .spanDiv {
      margin: 50px 0px 50px 0px;
    }

    .fontTookKitMaster {
      font-size: 35px;
    }

    .fontContentText {
      font-size: 18px !important;
    }

    .fontContentVideo {
      font-size: 25px !important;
    }

    .CarosuelVideoStyle1 {
      width: 97% !important;
      height: 208px !important;
    }

    .CardStyles {
      height: 246px !important;
    }
  }
}

@media (min-width: 1200px) and (max-width: 1360px) {
  .LegendDiv {
    width: 80%;
    position: relative;
    left: 10%;

    .spanDiv {
      margin: 50px 0px 50px 0px;
    }

    .fontTookKitMaster {
      font-size: 35px;
    }

    .fontContentText {
      font-size: 15px !important;
    }

    .fontContentVideo {
      font-size: 20px !important;
    }

    .CarosuelVideoStyle1 {
      width: 97% !important;
      height: 208px !important;
    }

    .CardStyles {
      height: 246px !important;
    }
  }
}

@media (min-width: 768px) and (max-width: 1199px) {
  .LegendDiv {
    width: 90% !important;
    position: relative;
    left: 5% !important;

    .spanDiv {
      margin: 50px 0px 50px 0px;
    }

    .fontTookKitMaster {
      font-size: 25px !important;
    }

    .fontContentText {
      font-size: 23px !important;
    }

    .fontContentVideo {
      font-size: 30px !important;
    }

    .TextPadding {
      padding: 30px !important;
      padding-top: 40px !important;
    }
  }
}

@media (min-width: 600px) and (max-width: 767px) {
  .LegendDiv {
    width: 90% !important;
    position: relative;
    left: 5% !important;

    .spanDiv {
      margin: 50px 0px 50px 0px;
    }

    .fontTookKitMaster {
      font-size: 21px !important;
    }

    .fontContentText {
      font-size: 18px !important;
    }

    .fontContentVideo {
      font-size: 25px !important;
    }

    .TextPadding {
      padding: 30px !important;
      padding-top: 40px !important;
    }
  }
}

@media (min-width: 480px) and (max-width: 599px) {
  .LegendDiv {
    width: 100% !important;
    position: relative;
    left: 0% !important;

    .spanDiv {
      margin: 50px 0px 50px 0px;
    }

    .fontTookKitMaster {
      font-size: 15px !important;
    }

    .fontContentText {
      font-size: 20px !important;
    }

    .fontContentVideo {
      font-size: 25px !important;
    }

    .CarosuelVideoStyle1 {
      width: 93% !important;
      margin-left: 17px !important;
    }

    .TextPadding {
      padding-top: unset !important;
      padding: 0px 30px 30px 30px !important;
    }

    .CardStyles {
      height: unset !important;
    }
  }
}

@media (min-width: 380px) and (max-width: 479px) {
  .LegendDiv {
    width: 100% !important;
    position: relative;
    left: 0% !important;

    .spanDiv {
      margin: 50px 0px 50px 0px;
    }

    .fontTookKitMaster {
      font-size: 14px !important;
    }

    .fontContentText {
      font-size: 15px !important;
    }

    .fontContentVideo {
      font-size: 20px !important;
    }

    .CarosuelVideoStyle1 {
      width: 91% !important;
      margin-left: 17px !important;
    }

    .TextPadding {
      padding-top: unset !important;
      padding: 0px 30px 30px 30px !important;
    }

    .CardStyles {
      height: unset !important;
    }
  }
}

@media (max-width: 379px) {
  .LegendDiv {
    width: 100% !important;
    position: relative;
    left: 0% !important;

    .spanDiv {
      margin: 50px 0px 50px 0px;
    }
    .fontTookKitMaster {
      font-size: 13px !important;
    }

    .fontContentText {
      font-size: 15px !important;
    }

    .fontContentVideo {
      font-size: 20px !important;
    }

    .CarosuelVideoStyle1 {
      width: 89% !important;
      margin-left: 17px !important;
    }

    .TextPadding {
      padding-top: unset !important;
      padding: 0px 30px 30px 30px !important;
    }

    .CardStyles {
      height: unset !important;
    }
  }
}

// media Querie for carousel div
@media (min-width: 1336px) and (max-width: 2000px) {
  .WordsDiv {
    width: 84%;
    left: 8%;
  }

  .slick-slide {
    padding-left: 46px !important;
  }

  .fontContentText1 {
    font-size: 21px !important;
  }
}

@media (min-width: 993px) and (max-width: 1224px) {
  .WordsDiv {
    width: 84%;
    left: 8%;
  }

  .slick-slide {
    padding-left: 50px !important;
  }

  .contentWidth {
    width: 50%;
  }

  .slick-slide {
    padding-left: 46px !important;
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  .WordsDiv {
    width: 84%;
    left: 8%;
  }

  .CardStylesCarousel {
    height: unset !important;
  }

  .CardStylesCarousel {
    height: unset !important;
  }

  .slick-slide {
    padding-left: 46px !important;
  }

  .contentWidth {
    width: 70%;
  }

  .CarosuelVideoStyle {
    width: 81% !important;
    margin-top: 43px !important;
    margin-left: 54px !important;
    height: 361px !important;
  }
}

@media (min-width: 641px) and (max-width: 767px) {
  .WordsDiv {
    width: 90%;
    left: 5%;

    .CardStylesCarousel {
      height: unset !important;
    }

    .CardStylesCarousel {
      height: unset !important;
    }

    .slick-slide {
      padding-left: 30px !important;
      padding-right: 0px !important;
    }

    .contentWidth {
      margin-top: -15px !important;
      width: 100%;
      padding-left: 10% !important;
    }

    .CarosuelVideoStyle {
      width: 81% !important;
      margin-top: 43px !important;
      margin-left: 44px !important;
      height: 361px !important;
    }
  }
}

@media (min-width: 480px) and (max-width: 640px) {
  .LoginPage {
    .joinButton {
      font-size: 20px !important;
      padding: 10px 43px !important;
    }
  }

  .WordsDiv {
    width: 90%;
    left: 5%;

    .spanDiv {
      margin: 81px 0px 0px 0px !important;
    }

    .CardStylesCarousel {
      height: unset !important;
    }

    .CardStylesCarousel {
      height: unset !important;
    }

    .contentWidth {
      margin-top: -25px !important;
      width: 100%;
      padding-left: 5% !important;
    }

    .CarosuelVideoStyle {
      width: 92% !important;
      margin-top: 22px !important;
      // margin-left: 40px !important;
      height: 231px !important;
    }

    // .slick-list {
    //   width: 136%;
    //   position: relative;
    //   left: -19%;
    // }

    // .slick-prev {
    //   position: absolute;
    //   left: 34%;
    // }

    // .slick-next {
    //   position: absolute;
    //   right: 37%;
    // }

    // .slick-next,
    // .slick-prev {
    //   top: 97%;
    // }

    // .slick-slide {
    //   padding-left: 30px !important;
    //   padding-right: 4px !important;
    // }
  }
}

@media (min-width: 360px) and (max-width: 479px) {
  .LoginPage {
    .joinButton {
      font-size: 15px !important;
      padding: 10px 33px !important;
    }
  }

  .WordsDiv {
    width: 90%;
    left: 5%;

    .spanDiv {
      margin: 81px 0px 0px 0px !important;
    }

    // .slick-list {
    //   width: 136%;
    //   position: relative;
    //   left: -19%;
    // }

    // .slick-prev {
    //   position: absolute;
    //   left: 34%;
    // }

    // .slick-next {
    //   position: absolute;
    //   right: 37%;
    // }

    // .slick-next,
    // .slick-prev {
    //   top: 97%;
    // }

    // .slick-slide {
    //   padding-left: 30px !important;
    //   padding-right: 4px !important;
    // }

    .CardStylesCarousel {
      height: unset !important;
    }

    .CardStylesCarousel {
      height: unset !important;
    }

    .contentWidth {
      width: 100%;
      margin-top: -20px !important;
      padding-left: 10% !important;
    }

    .CarosuelVideoStyle {
      width: 85% !important;
      margin-top: 22px !important;
      // margin-left: 40px !important;
      height: 200px !important;
    }

    .fontContentText1 {
      font-size: 13px !important;
      line-height: 25px !important;
    }

    .contentWidth {
      .fontContentVideo {
        font-size: 15px !important;
        line-height: 20px;
      }
    }
  }
}

@media (max-width: 359px) {
  .LoginPage {
    .joinButton {
      font-size: 13px !important;
      padding: 8px 33px !important;
    }
  }

  .WordsDiv {
    width: 90%;
    left: 5%;

    .spanDiv {
      margin: 81px 0px 0px 0px !important;
    }
  }

  .CardStylesCarousel {
    height: unset !important;
  }

  .CardStylesCarousel {
    height: unset !important;
  }

  // .slick-slide {
  //   padding-left: 30px !important;
  //   padding-right: 4px !important;
  // }

  .contentWidth {
    width: 100%;
    padding-left: 5% !important;
    margin-top: -14px !important;
  }

  .CarosuelVideoStyle {
    width: 81% !important;
    margin-top: 22px !important;
    height: 158px !important;
  }

  .fontContentText1 {
    font-size: 14px !important;
  }

  .contentWidth {
    .fontContentVideo {
      font-size: 15px !important;
      line-height: 20px;
    }
  }
}

// OGGG
@media (min-width: 1771px) and (max-width: 1900px) {
  .LoginPage {
    .OGGGDiv {
      height: 700px !important;
    }
  }
}

@media (min-width: 1569px) and (max-width: 1770px) {
  .LoginPage {
    .OGGGDiv {
      height: 600px !important;
    }
  }
}

@media (min-width: 1336px) and (max-width: 1568px) {
  .LoginPage {
    .OGGGDiv {
      height: 550px !important;
    }
  }
}

@media (min-width: 1900) and (max-width: 2000px) {
  .LoginPage {
    .OGGGDiv {
      height: 600px !important;
    }
  }
}

@media (min-width: 1336px) and (max-width: 2000px) {
  .LoginPage {
    .OGGGDiv {
      .spanHeaderButton {
        padding: 0px 90px !important;
        font-size: 30px !important;
      }
    }
  }
}

@media (min-width: 1225px) and (max-width: 1335px) {
  .LoginPage {
    .OGGGDiv {
      height: 450px !important;
    }
  }
}

@media (min-width: 993px) and (max-width: 1224px) {
  .LoginPage {
    .OGGGDiv {
      height: 400px !important;
    }
  }
}

@media (min-width: 993px) and (max-width: 1335px) {
  .LoginPage {
    .OGGGDiv {
      .HeaderImage {
        // height: 300px !important;
        width: 60vw;
        margin-top: -100px;
        left: -8% !important;
      }
      .spanHeaderButton {
        padding: 0px 70px !important;
        font-size: 25px !important;
        margin-top: 15px !important;
      }

      .HeaderImgTextDisplayPos {
        bottom: 53% !important;
      }
    }
  }
}

@media (min-width: 879px) and (max-width: 992px) {
  .LoginPage {
    .OGGGDiv {
      height: 380px !important;
    }
  }
}

@media (min-width: 768px) and (max-width: 878px) {
  .LoginPage {
    .OGGGDiv {
      height: 310px !important;
    }
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  .LoginPage {
    .OGGGDiv {
      // height: 350px !important;
      .HeaderImage {
        // height: 300px !important;
        width: 80vw;
        margin-top: -60px;
        left: -8% !important;
      }
      .fontBecomeProButton {
        font-size: 30px !important;
        padding: 0px 20px !important;
      }

      .spanHeaderImageFontStyles {
        line-height: 0px !important;
      }

      .HeaderImgTextDisplayPos {
        bottom: 48% !important;
      }
    }
  }
}

@media (min-width: 641px) and (max-width: 767px) {
  .LoginPage {
    .OGGGDiv {
      height: 270px !important;

      .HeaderImage {
        width: 81vw;
        margin-top: -47px !important;
        left: -12% !important;
      }

      .fontBecomeProButton {
        font-size: 23px !important;
        padding: 4px 30px !important;
        margin-top: 20px !important;
      }

      .spanHeaderImageFontStyles {
        line-height: 30px !important;
      }

      .HeaderImgTextDisplayPos {
        bottom: 46% !important;
        left: -4% !important;
      }
    }
  }
}

// @media (min-width: 530px) and (max-width: 640px) {
//   .LoginPage {
//     .OGGGDiv {
//       height: 400px !important;
//     }
//   }
// }

// @media (min-width: 480px) and (max-width: 529px) {
//   .LoginPage {
//     .OGGGDiv {
//       height: 350px !important;
//     }
//   }
// }

@media (min-width: 480px) and (max-width: 640px) {
  .LoginPage {
    .OGGGDiv {
      height: 180px !important;

      .HeaderImage {
        width: auto !important;
        margin-top: -12% !important;
        height: 402px;
        left: -10%;
      }

      .spanHeaderButton {
        padding: 0px 30px !important;
      }
      .fontBecomeProButton {
        font-size: 20px !important;
        margin-top: 25px !important;

        svg {
          width: 20px !important;
        }
      }

      .spanHeaderImageFontStyles {
        line-height: 0px !important;
      }

      .HeaderImgTextDisplayPos {
        bottom: 46% !important;
        // left: -5%;
      }
    }
  }
}

@media (min-width: 380px) and (max-width: 479px) {
  .LoginPage {
    .OGGGDiv {
      height: 300px !important;

      .HeaderImage {
        width: auto !important;
        height: 384px !important;
        margin-top: -64px !important;
        left: -13% !important;
      }

      .spanHeaderButton {
        padding: 0px 10px !important;
        margin-top: 20px !important;
      }

      .fontBecomeProButton {
        font-size: 15px !important;

        svg {
          width: 12px !important;
        }
      }

      .spanHeaderImageFontStyles {
        line-height: 0px !important;
      }

      .HeaderImgTextDisplayPos {
        bottom: 48% !important;
        left: -2% !important;
      }
    }
  }
}

@media (max-width: 379px) {
  .LoginPage {
    .OGGGDiv {
      height: 249px !important;

      .HeaderImage {
        width: 100%;
        height: 370px !important;
        margin-top: -49px !important;
        left: -16% !important;
        overflow-x: hidden;
      }

      .spanHeaderButton {
        padding: 0px 10px !important;
        margin-top: 20px !important;
      }

      .fontBecomeProButton {
        font-size: 15px !important;

        svg {
          width: 12px !important;
        }
      }

      .spanHeaderImageFontStyles {
        line-height: 0px !important;
      }

      .HeaderImgTextDisplayPos {
        bottom: 47% !important;
        left: 0% !important;
      }
    }
  }
}

// getCoach
@media (min-width: 1500px) and (max-width: 2000px) {
  .LoginPage {
    .getCoachDiv {
      .imageCardCoaches {
        img {
          width: 65% !important;
          left: 20% !important;
          height: 370px !important;
        }

        // .namePosition {
        //   left: 47% !important;
        //   bottom: 10% !important;
        // }
        // .namePosition1 {
        //   left: 47% !important;
        //   bottom: 10% !important;
        // }
      }
    }
  }
}

@media (min-width: 1336px) and (max-width: 1499px) {
  .LoginPage {
    .getCoachDiv {
      .imageCardCoaches {
        img {
          width: 65% !important;
          left: 20% !important;
          height: 350px !important;
        }

        .namePrathika,
        .nameBrad {
          font-size: 30px !important;
        }

        .nameGame {
          font-size: 25px !important;
        }

        // .namePosition {
        //   left: 47% !important;
        //   bottom: 10% !important;
        // }
        // .namePosition1 {
        //   left: 47% !important;
        //   bottom: 10% !important;
        // }
      }
    }
  }
}

// @media (min-width: 1336px) and (max-width: 1499px){
//   .LoginPage {
//     .getCoachDiv {
//       .imageCardCoaches {
//          .namePosition{
//           // left: -14% !important;
//          }
//       }}}
// }

@media (min-width: 1025px) and (max-width: 1335px) {
  .LoginPage {
    .getCoachDiv {
      .imageCardCoaches {
        img {
          width: 80% !important;
          left: 10% !important;
          height: 350px !important;
        }

        .namePrathika,
        .nameBrad {
          font-size: 30px !important;
        }

        .nameGame {
          font-size: 25px !important;
        }

        .namePosition {
          left: -14% !important;
        }

        .namePosition1 {
          left: 0% !important;
        }

        .imagePrathik {
          left: 4% !important;
        }
      }
    }
  }
}

@media (max-width: 900px) {
  .LoginPage {
    .imageCardCoaches {
      border-radius: 1% !important;
    }
  }
}

@media (min-width: 899px) and (max-width: 1024px) {
  .LoginPage {
    .everyDiv {
      margin-top: 0% !important;
    }

    .getCoachDiv {
      margin-top: 40px;
      width: 100% !important;
      left: 0%;
    }

    .namePrathika,
    .nameBrad {
      font-size: 30px !important;
    }

    .nameGame {
      font-size: 25px !important;
    }

    .fontCoach {
      font-size: 27px !important;
    }

    .imageCardCoaches {
      height: 560px !important;
      margin-bottom: 70px !important;

      img {
        width: 80% !important;
        left: 20% !important;
        height: 350px !important;
      }

      .namePosition {
        left: -2% !important;
      }

      .namePosition1 {
        left: 8% !important;
      }

      .imagePrathik {
        left: 7% !important;
      }
    }

    // .spanDiv {
    //   margin: 1px 0px -50px 0px !important;
    // }
  }
}

@media (min-width: 640px) and (max-width: 898px) {
  .LoginPage {
    .everyDiv {
      margin-top: 11% !important;
    }

    .getCoachDiv {
      margin-top: 40px;
      width: 100%;
      left: 0%;
    }

    .fontCoach {
      font-size: 27px !important;
    }

    .namePrathika,
    .nameBrad {
      font-size: 30px !important;
    }

    .nameGame {
      font-size: 25px !important;
    }

    .imageCardCoaches {
      height: auto !important;
      margin-bottom: 130px !important;

      img {
        width: 50% !important;
        left: 25% !important;
        height: 380px !important;
      }

      .namePosition {
        left: -2% !important;
      }

      .namePosition1 {
        left: 0% !important;
      }
    }

    // .spanDiv{
    //   margin: 1px 0px -50px 0px  !important;
    // }
  }
}

@media (min-width: 480px) and (max-width: 640px) {
  .LoginPage {
    // .everyDiv {
    //   margin-top: -111px !important;
    // }

    .getCoachDiv {
      width: 100%;
      left: 0%;
      margin-bottom: 150px !important;
    }

    .fontCoach {
      font-size: 25px !important;
    }

    .namePrathika,
    .nameBrad {
      font-size: 30px !important;
    }

    .nameGame {
      font-size: 25px !important;
    }

    .imageCardCoaches {
      height: auto !important;

      img {
        width: 60% !important;
        left: 20% !important;
        height: 330px !important;
      }

      .namePosition {
        left: -2% !important;
      }

      .namePosition1 {
        left: -2% !important;
      }
    }

    // .spanDiv {
    //   margin: 1px 0px -50px 0px !important;
    // }
  }
}

@media (min-width: 360px) and (max-width: 480px) {
  .LoginPage {
    .getCoachDiv {
      width: 100%;
      left: 0%;
    }

    .fontCoach {
      font-size: 20px !important;
    }
  }

  .namePrathika,
  .nameBrad {
    font-size: 25px !important;
  }

  .nameGame {
    font-size: 20px !important;
  }

  .imageCardCoaches {
    height: auto !important;

    img {
      width: 80% !important;
      left: 10% !important;
      height: 290px !important;
    }

    .namePosition {
      left: -2% !important;
    }

    .namePosition1 {
      left: -2% !important;
    }
  }
}

@media (max-width: 360px) {
  .LoginPage {
    .getCoachDiv {
      width: 100%;
      left: 0%;
    }

    .fontCoach {
      font-size: 16px !important;
    }
  }

  .namePrathika,
  .nameBrad {
    font-size: 25px !important;
  }

  .nameGame {
    font-size: 20px !important;
  }

  .imageCardCoaches {
    height: auto !important;

    img {
      width: 80% !important;
      left: 10% !important;
      height: 290px !important;
    }

    .namePosition {
      left: -2% !important;
    }

    .namePosition1 {
      left: -2% !important;
    }
  }
}

@media (max-width: 868px) {
  .LegendDiv {
    .imageCardCoaches {
      border-radius: 10px !important;
    }
  }
}

// choose Game
@media (min-width: 1336px) and (max-width: 2000px) {
  .LoginPage {
    .ChooseGameDiv {
      width: 80% !important;
      left: 10% !important;

      .imageValorant {
        position: relative;
        left: 5%;
      }

      .imageBgmi {
        position: relative;
        right: 10% !important;
      }

      .textAlignStylesB {
        padding-right: 44px !important;
      }

      .textAlignStylesV {
        padding-right: 94px !important;
      }
    }
  }
}

@media (min-width: 1224px) and (max-width: 1336px) {
  .LoginPage {
    .ChooseGameDiv {
      width: 90% !important;
      left: 5% !important;

      .imageValorant {
        position: relative;
        left: 5%;
      }

      .imageBgmi {
        position: relative;
        right: 5% !important;
      }

      .textAlignStylesB {
        padding-right: 24px !important;
      }

      .textAlignStylesV {
        padding-left: 60px !important;
        padding-right: 60px !important;
      }
    }
  }
}

@media (min-width: 899px) and (max-width: 1224px) {
  .LoginPage {
    .ChooseGameDiv {
      width: 100% !important;
      left: 0% !important;

      .imageValorant {
        position: relative;
        left: 4%;
      }

      .imageBgmi {
        position: relative;
        right: 5% !important;
      }

      .textAlignStylesB {
        padding-right: 24px !important;
        margin-left: 47px;
        margin-right: -25px;
    
      }

      .textAlignStylesV {
        padding-left: 45px !important;
        padding-right: 19px !important;
      }
    }

    .imageCard {
      img {
        width: 70% !important;
      }
    }
  }
}

@media (min-width: 768px) and (max-width: 898px) {
  .LoginPage {
    .ChooseGameDiv {
      height: 100% !important;
      width: 80% !important;
      left: 10% !important;

      .imageCard {
        height: auto !important;
      }

      .fontMvpbW {
        font: 20px !important;
      }

      .imageValorant {
        position: relative;
        left: 0%;
      }

      .imageBgmi {
        position: relative;
        right: 0% !important;
      }

      .textAlignStylesB {
        padding-right: 54px !important;
        margin-left: 47px;
        margin-right: -25px;
      }

      .textAlignStylesV {
        padding-left: 45px !important;
        padding-right: 69px !important;
      }
    }

    .imageCard {
      img {
        width: 70% !important;
        left: 15% !important;
      }
    }
  }
}

@media (min-width: 640px) and (max-width: 768px) {
  .LoginPage .ChooseGameDiv .fontMvpbW {
    font-size: 21px !important;
  }

  .LoginPage {
    .ChooseGameDiv {
      height: 100% !important;
      width: 80% !important;
      left: 10% !important;

      .imageCard {
        height: auto !important;
      }

      .imageValorant {
        position: relative;
        left: 0%;
      }

      .imageBgmi {
        position: relative;
        right: 0% !important;
      }

      .textAlignStylesB {
        padding-right: 54px !important;
        margin-left: 47px;
        margin-right: unset;
      }

      .textAlignStylesV {
        padding-left: 45px !important;
        padding-right: 69px !important;
      }
    }

    .imageCard {
      img {
        width: 70% !important;
        left: 15% !important;
      }
    }
  }
}

@media (min-width: 480px) and (max-width: 640px) {
  .LoginPage .ChooseGameDiv .fontMvpbW {
    font-size: 16px !important;
  }

  .LoginPage {
    .ChooseGameDiv {
      height: 100% !important;
      width: 80% !important;
      left: 10% !important;

      .imageCard {
        height: auto !important;
        border-radius: 5px !important;
      }

      .imageValorant {
        position: relative;
        left: 0%;

        img {
          height: 300px !important;
        }
      }

      .imageBgmi {
        position: relative;
        right: 0% !important;

        img {
          height: 300px !important;
        }
      }

      .fontVolorant {
        font-size: 20px !important;
      }

      .fontBGMI {
        font-size: 20px !important;
      }

      .fontContentStyle {
        font-size: 16px !important;
      }

      .textAlignStylesB {
        padding-left: 30px !important;
        padding-right: 20px !important;
        margin-left: unset;
        margin-right: unset;
      }

      .textAlignStylesV {
        padding-left: 30px !important;
        padding-right: 20px !important;
      }
    }

    .imageCard {
      img {
        width: 70% !important;
        left: 15% !important;
      }
    }
  }
}

@media (min-width: 380px) and (max-width: 479px) {
  .LoginPage .ChooseGameDiv .fontMvpbW {
    font-size: 14px !important;
  }

  .LoginPage {
    .ChooseGameDiv {
      height: 100% !important;
      width: 100% !important;
      left: 0% !important;

      .imageCard {
        height: auto !important;
      }

      .imageValorant {
        position: relative;
        left: 0%;
      }

      .imageBgmi {
        position: relative;
        right: 0% !important;
      }

      .fontVolorant {
        font-size: 20px !important;
      }

      .fontBGMI {
        font-size: 20px !important;
      }

      .fontContentStyle {
        font-size: 16px !important;
      }

      .textAlignStylesB {
        padding-left: 30px !important;
        padding-right: 20px !important;
        margin-left: unset;
        margin-right: unset;
      }

      .textAlignStylesV {
        padding-left: 30px !important;
        padding-right: 20px !important;
      }
    }

    .imageCard {
      img {
        width: 70% !important;
        left: 15% !important;
        height: 200px !important;
      }
    }
  }
}

@media (max-width: 379px) {
  .LoginPage .ChooseGameDiv .fontMvpbW {
    font-size: 14px !important;
  }

  .LoginPage {
    .ChooseGameDiv {
      height: 100% !important;
      width: 100% !important;
      left: 0% !important;

      .imageCard {
        height: auto !important;
      }

      .imageValorant {
        position: relative;
        left: 0%;
      }

      .imageBgmi {
        position: relative;
        right: 0% !important;
      }

      .fontVolorant {
        font-size: 20px !important;
      }

      .fontBGMI {
        font-size: 20px !important;
      }

      .fontContentStyle {
        font-size: 16px !important;
      }

      .textAlignStylesB {
        padding-left: 30px !important;
        padding-right: 20px !important;
        margin-left: unset;
        margin-right: unset;
      }

      .textAlignStylesV {
        padding-left: 30px !important;
        padding-right: 20px !important;
      }
    }

    .imageCard {
      img {
        width: 70% !important;
        left: 15% !important;
        height: 200px !important;
      }
    }
  }
}

@media (max-width: 600px) {
  .imageShadowValorant {
    box-shadow: inset 0 9px 0px 138px #000, 0 -9px 0px -15px #26286e,
      -232px 8px 150px -40px rgb(18 51 114 / 66%),
      -50px -24px 44px -7px rgb(18 18 153 / 30%),
      47px -26px 48px -22px rgb(166 137 27 / 91%) !important;
  }

  .imageShadowbgmi {
    box-shadow: inset 0 0, 0 -9px 0px -15px #091f3f,
      -96px -49px 72px -52px rgb(15 47 109 / 99%),
      10px 6px 30px 80px rgb(22 22 23 / 30%),
      147px 4px 89px -53px rgb(28 212 205 / 69%) !important;
  }
}

@media (max-width: 640px) {
  .imageShadowbgmi {
    box-shadow: inset 0 0, 0 -9px 0px -15px #091f3f,
      -96px -49px 72px -52px rgb(15 47 109 / 99%),
      10px 6px 30px 80px rgb(22 22 23 / 30%),
      147px 4px 89px -53px rgb(28 212 205 / 69%) !important;
  }
  .googleIcon{
    font-size: 30px;
          margin-top: 10px;
  }
}

.imageShadowValorant {
  box-shadow: inset 0 0, -91px 8px 150px -40px rgb(18 51 114 / 66%),
    147px -29px 122px -61px rgb(166 137 27 / 91%);
  // box-shadow: inset 0 0, -91px 8px 150px -40px rgb(18 51 114 / 66%), 147px -54px 180px -66px rgb(166 137 27 / 91%);
  // box-shadow: inset 0 9px 0px 138px #000, -232px 8px 150px -40px rgb(18 51 114 / 66%), 300px 3px 300px 800px rgb(22 22 23 / 30%), 147px -54px 180px -66px rgb(166 137 27 / 91%);
}

.imageShadowbgmi {
  box-shadow: inset 0 0, 0 -9px 0px -15px #091f3f,
    -159px -121px 119px -95px rgb(15 47 109 / 99%),
    10px 6px 30px 80px rgb(22 22 23 / 30%),
    121px 8px 123px -100px rgb(28 212 205 / 69%);
}

.imagePrathik {
  box-shadow: inset 0 0, -91px 8px 150px -40px rgb(18 51 114 / 66%),
    147px -29px 122px -61px rgb(47 124 121 / 91%);
}

.imageBrad {
  box-shadow: inset 0 0, -98px 45px 150px -40px rgb(18 51 114 / 66%),
    147px -29px 122px -61px rgb(18 51 114 / 71%);

  // box-shadow: inset 0 0, 0 -9px 0px -15px #091f3f, -159px -121px 119px -95px rgb(15 47 109 / 99%), 10px 6px 30px 80px rgb(22 22 23 / 30%), 147px 4px 156px -78px rgb(28 212 205 / 69%);
  // box-shadow: inset 0 0, -91px 8px 150px -40px rgb(18 51 114 / 66%), 147px -29px 122px -61px rgb(47 124 121 / 91%);
}

// inset 0 0, -232px 8px 150px -40px rgb(18 51 114 / 66%)
.checkbox {
  input {
    width: 27px;
  }
}
@media (max-width: 1336px) {
  .imageShadowbgmi {
    box-shadow: inset 0 0, 0 -9px 0px -15px #091f3f,
      -159px -121px 119px -95px rgb(15 47 109 / 99%),
      10px 6px 30px 80px rgb(22 22 23 / 30%),
      99px 10px 106px -86px rgb(28 212 205 / 69%) !important;
  }
}
@media (max-width: 1124px) {
  .imageShadowbgmi {
    box-shadow: inset 0 0, 0 -9px 0px -15px #091f3f,
      -105px -121px 119px -95px rgb(15 47 109 / 99%),
      10px 6px 30px 80px rgb(22 22 23 / 30%),
      99px 10px 83px -86px rgb(28 212 205 / 69%) !important;
  }
}
@media (max-width: 480px) {
  .imageShadowbgmi {
    box-shadow: inset 0 0, 0 -9px 110px -24px #091f3f, -105px -23px 147px -23px rgb(15 47 109 / 99%), 10px 6px 30px 80px rgb(22 22 23 / 30%), 99px 10px 83px -54px rgb(28 212 205 / 69%) !important;

  }
  .setting{
    .formLoginCard {
      padding: 16px;
  }
    .form{
      h4{
        padding-top: 20px;
        color: #fff;
      }
      padding: 1px !important;
    }
  }
}
.form{
  h4{
    color: #fff;
  }
}
@media (min-width:993px) and (max-width:1300px){
  .googleIcon{
    margin-top: unset;
    font-size: 50px;
  }
}
@media (min-width:641px) and (max-width:992px){
  .googleIcon{
    margin-top:-6px;
    font-size: 40px;
  }
}