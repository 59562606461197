// footer
.footerDiv {
  .faIconsStyles-Facebook:hover {
    cursor: pointer;
    color: blue !important;
  }
  .faIconsStyles-instagram:hover {
    cursor: pointer;
    color: #c13584 !important;
  }
  .faIconsStyles-youtube:hover {
    cursor: pointer;
    color: red !important;
  }
  .faIconsStyles-discord:hover {
    cursor: pointer;
    color: #5865f2 !important;
  }
  .fontFooterLink {
    font-family: SamsungSansLight;
    font-size: 25px;
    font-weight: 1000;
    // text-transform: capitalize;
  }

  .fontFollow {
    font-family: SamsungSansLight;
    font-size: 27px;
    font-weight: 900;
  }

  .fontEmail {
    font-family: SamsungSansLight;
    font-size: 19px;
  }
}
.TeamHeader {
  font-weight: 1000;
}
// frequently questions
.frequentlyParent {
  margin-top: 30px;
}
.QuesitionCard {
  margin: 20px;
  padding: 10px;
  color: #070707;
  text-align: center;
}
.QuestionAnswer {
  font-size: 30px;
  margin: 10px;
  margin-left: 72px;
  color: #fff;
  padding-right: 30px !important;
}
// carousel for stucture , pro and practice
.WhatCaurosel {
  img {
    width: 100%;
    height: 500px;
    object-fit: cover;
    opacity: 0.6;
  }
  .structuredDiv {
    position: absolute;
    top: 382px;
    width: 100%;
    font-size: 66px;
    padding-left: 25px;
    h1 {
      font-size: 49px;
      line-height: 52px !important;
      color: #fff;
    }
  }
}
.whatCardCarousel {
  background-color: #191515;
  border-radius: 15px;
}
.structuredDiv1 {
  width: 99%;
  padding-left: 17px;
  padding-top: 22px;
  font-size: 27px;
  color: #fff;
  height: 462px;
}
// .fontFooterLinkColor{
//   color: blue !important;
// }
// what our geeks
.CardCoursel1 {
  height: 250px;
  background-color: #f0e7e7 !important;
  border-radius: 15px !important;
}
.CourselCircle {
  height: 224px;
  background-color: #000;
  border-radius: 50%;
  width: 80%;
  margin-left: 45px;
  margin-top: 14px;
}
.CourselText {
  position: absolute;
  top: 50px;
  span {
    font-size: 25px;
  }
}
// your words
.YourwordCarousal1 {
  padding: 87px 0px !important;
  opacity: 0.8;
  span {
    font-size: 34px !important;
    font-weight: 900 !important;
    color: white;
  }
}
.YourwordCarousal2 {
  margin-top: 30px;
  // background-color: #040503 !important;
  padding: 30px 0px !important;
  span {
    font-size: 34px !important;
    font-weight: 900 !important;
    color: white;
  }
}
// media queries
@media (min-width: 1336px) and (max-width: 2000px) {
  .footerDivParent {
    .footerDiv {
      .fontFooterLink {
        font-size: 25px;
        font-weight: 1000;
      }

      .fontFollow {
        font-size: 27px;
      }

      .fontEmail {
        font-size: 23px;
      }
    }
  }
}
@media (min-width: 1300px) and (max-width: 1620px) {
  // what more caurosel
  .WhatCaurosel {
    img {
      height: 400px;
    }
    .structuredDiv {
      top: 282px;
      h1 {
        font-size: 41px;
      }
    }
    .structuredDiv1 {
      font-size: 24px;
    }
  }
}
@media (min-width: 993px) and (max-width: 1335px) {
  .structuredDiv1 {
    font-size: 24px;
    padding: 15px;
  }
  // footer div
  .footerDivParent {
    .footerDiv {
      .fontFooterLink {
        font-size: 20px !important;
      }

      .fontFollow {
        font-size: 27px;
      }

      .fontEmail {
        font-size: 20px;
      }
    }
  }
}
@media (min-width: 1025px) and (max-width: 1199px) {
  .YourwordCarousal1,
  .YourwordCarousal2 {
    span {
      font-size: 30px !important;
    }
  }
}
@media (min-width: 1225px) and (max-width: 1300px) {
  // what more caurosel
  .WhatCaurosel {
    img {
      height: 400px;
    }
    .structuredDiv {
      top: 280px;
      h1 {
        font-size: 35px;
      }
    }
    .structuredDiv1 {
      font-size: 20px;
      height: 360px;
    }
  }
}
@media (min-width: 1074px) and (max-width: 1224px) {
  .structuredDiv1 {
    font-size: 24px;
    height: 348px;
  }
}
@media (min-width: 993px) and (max-width: 1073px) {
  .structuredDiv1 {
    font-size: 24px;
    height: 448px;
  }
}
@media (min-width: 993px) and (max-width: 1224px) {
  .slick-next:before,
  .slick-prev:before {
    font-size: 57px;
  }
  // what more caurosel
  .WhatCaurosel {
    img {
      height: 400px;
    }
    .structuredDiv {
      top: 295px;
      h1 {
        font-size: 49px;
      }
    }
   
  }
  // greeks carousel
  .CardCoursel1 {
    width: 580px !important;
    height: 260px;
  }
  .CourselCircle {
    width: 90%;
    margin-left: 15px;
    margin-top: 16px;
  }
}
@media (min-width: 899px) and (max-width: 1199px) {
  .YourwordCarousal1,
  .YourwordCarousal2 {
    span {
      font-size: 30px !important;
    }
  }
}
@media (min-width: 640px) and (max-width: 898px) {
  .YourwordCarousal1,
  .YourwordCarousal2 {
    span {
      font-size: 25px !important;
    }
  }
}
@media (max-width: 992px) {
  .whatCardCarousel {
    .structuredDiv1 {
      height: auto;
      padding-bottom: 30px;
    }
  }
}
@media (min-width: 600px) and (max-width: 992px) {
  .footerDivParent {
    .faIconsStyles {
      font-size: 28px;
    }
    .footerDiv {
      .fontFooterLink {
        font-size: 15px !important;
      }

      .fontFollow {
        font-size: 25px;
      }

      .fontEmail {
        font-size: 13px;
      }
    }
  }
}

@media (min-width: 480px) and (max-width: 599px) {
  .footerDivParent {
    .faIconsStyles {
      font-size: 25px;
    }

    .footerDiv {
      .fontFooterLink {
        font-size: 11px !important;
      }

      .fontFollow {
        font-size: 15px;
      }

      .fontEmail {
        font-size: 10px;
      }
    }
  }
}
@media (min-width: 420px) and (max-width: 479px) {
  .footerDivParent {
    .footerDiv {
      .fontFooterLink {
        font-size: 8px !important;
      }

      .fontFollow {
        font-size: 13px;
      }

      .fontEmail {
        font-size: 7px;
      }
    }
  }
}

@media (min-width: 769px) and (max-width: 992px) {
  .whatCardCarousel {
    .structuredDiv {
      top: 431px;
    }
  }
  .frequentlyParent {
    padding: unset;
  }
  .QuesitionCard {
    h2 {
      font-size: 28px;
    }
  }
  .QuestionAnswer {
    font-size: 24px;
    margin-left: 31px;
    padding-right: 30px !important;
  }
  // greeks carousel
  .CardCoursel1 {
    width: 580px !important;
    height: 260px;
  }
  .CourselCircle {
    width: 90%;
    margin-left: 15px;
    margin-top: 16px;
  }
}
@media (min-width: 768px) and (max-width: 992px) {
  .whatCardCarousel {
    .structuredDiv {
      top: 431px;
    }
  }
}
@media (min-width: 640px) and (max-width: 767px) {
  .whatCardCarousel {
    .structuredDiv {
      top: 390px;
      h1 {
        font-size: 40px;
      }
    }
  }
  .whatMoreParentDiv {
    .slick-prev {
      left: 75px !important;
    }
    .slick-next {
      right: 100px !important;
    }
    .slick-next:before,
    .slick-prev:before {
      font-size: 57px;
    }
  }
}

@media (min-width: 640px) and (max-width: 768px) {
  .whatMoreParentDiv {
    .slick-prev {
      left: 12% !important;
      z-index: 9999;
    }
    .slick-next {
      right: 17% !important;
    }
    .slick-next:before,
    .slick-prev:before {
      font-size: 57px;
    }
  }
  .whatCardCarousel {
    .structuredDiv {
      top: 386px;
      width: 80%;
    }
  }
  .frequentlyParent {
    padding: 10px 50px;
  }
  .QuesitionCard {
    h2 {
      font-size: 25px;
    }
  }
 
  .QuestionAnswer {
    font-size: 20px;
    margin-left: 31px;
    padding-right: 30px !important;
  }
  // greeks carousel
  .CardCoursel1 {
    width: 480px !important;
    height: 550px;
  }
  .CourselCircle {
    margin-left: 46px;
    margin-top: 25px;
    height: 300px;
  }
  .CourselText {
    top: 382px !important;
    padding-left: 29px;
    white-space: break-spaces;
  }
}
@media (min-width: 480px) and (max-width: 640px) {
  .whatMoreParentDiv {
    .slick-prev {
      left: 60px !important;
    }
    .slick-next {
      right: 90px !important;
    }
    .slick-next,
    .slick-prev {
      z-index: 99999;
      top: 43%;
    }
    .slick-next:before,
    .slick-prev:before {
      font-size: 50px;
    }
  }
  .whatCardCarousel {
    .structuredDiv {
      top: 450px;
      h1 {
        font-size: 35px;
      }
    }
  }
  // greeks carousel
  .CardCoursel1 {
    width: 380px !important;
    height: 450px;
  }
  .CourselCircle {
    margin-left: 31px;
    margin-top: 25px;
  }
  .CourselText {
    top: 311px !important;
    padding-left: 29px;
    white-space: break-spaces;
  }
}
@media (min-width: 380px) and (max-width: 480px) {
  .whatMoreParentDiv {
    .slick-prev {
      left: 15% !important;
    }
    .slick-next {
      right: 20% !important;
    }
    .slick-next,
    .slick-prev {
      z-index: 99999;
      top: 43%;
    }
    .slick-next:before,
    .slick-prev:before {
      font-size: 50px;
    }
  }
  // what more caurosel
  .WhatCaurosel {
    img {
      height: 400px;
    }
    .structuredDiv {
      top: 350px;
      h1 {
        font-size: 28px;
      }
    }
  }
  .structuredDiv1 {
    font-size: 20px;
    padding: 15px;
  }
  // greeks carousel
  .CardCoursel1 {
    width: 330px !important;
    height: 450px;
  }
  .CourselCircle {
    margin-left: 31px;
    margin-top: 25px;
  }
  .CourselText {
    top: 311px !important;
    padding-left: 29px;
    white-space: break-spaces;
  }
  // your words
  .YourwordCarousal1,
  .YourwordCarousal2 {
    span {
      font-size: 25px !important;
    }
  }
}

@media (max-width: 640px) {
  .frequentlyParent {
    padding: 10px 20px;
  }
  .QuesitionCard {
    h2 {
      font-size: 18px;
      line-height: 22px;
    }
  }
  .QuestionAnswer {
    font-size: 18px;
    margin-left: 31px;
    padding-right: 30px !important;
  }
}
@media (max-width: 380px) {
  .whatMoreParentDiv {
    .slick-prev {
      left: 15% !important;
    }
    .slick-next {
      right: 20% !important;
    }
    .slick-next,
    .slick-prev {
      z-index: 99999;
      top: 32%;
    }
    .slick-next:before,
    .slick-prev:before {
      font-size: 50px;
    }
  }
  .QuesitionCard {
    h2 {
      font-size: 14px;
      line-height: 22px;
    }
  }
  .QuestionAnswer {
    font-size: 14px;
    margin-left: 31px;
    padding-right: 30px !important;
  }
  // what more caurosel
  .WhatCaurosel {
    img {
      height: 300px;
    }
    .structuredDiv {
      top: 250px;
      h1 {
        font-size: 25px;
      }
    }
  }
  .structuredDiv1 {
    font-size: 20px;
    padding: 15px;
  }
  // greeks carousel
  .CardCoursel1 {
    width: 320px !important;
    height: 450px;
  }
  .CourselCircle {
    margin-left: 31px;
    margin-top: 25px;
  }
  .CourselText {
    top: 311px !important;
    padding-left: 29px;
    white-space: break-spaces;
  }
  // your words
  .YourwordCarousal1,
  .YourwordCarousal2 {
    span {
      font-size: 18px !important;
    }
  }
  .YourwordCarousal1 {
    padding: 41px !important;
    margin-top: 50px;
  }
}
.alignQuestion {
  display: flex;
  justify-content: center;
  align-items: center;
}
