@font-face {
  font-family: futuraFont;
  src: url(../../assets/fonts/Futura/FuturaLT-CondensedBold.ttf);
}
.react-select1__control {
  border-radius: 30px !important;
  cursor: pointer !important;
  input {
    // display: none;
    background-color: #fff !important;
    min-width: 100% !important;
  }
}
.react-select1__menu {
  background-color: #fff !important;
  color: #000;
  cursor: pointer !important;
}
.displayMobileInput {
  display: none !important;
}
.displayDesktopInput {
  display: block;
}
@media (max-width: 998px) {
  .displayMobileInput {
    display: flex !important;
  }
  .displayDesktopInput {
    display: none;
  }
}
.MessageCard {
  padding: 140px 20px;
  font-size: 20px;
}
.MainTournamentPage {
  background-image: url("../../assets/images/Artboard 1 .png");
  background-repeat: no-repeat;
  background-position-x: 100%;
  background-position-y: -58px;
  background-size: 60%;
}
.MainTournamentPage1 {
  background-image: url("../../assets/images/Artboard 2.png");
  background-repeat: no-repeat;
  // background-position-x: 51%;
  background-position-y: 27px;
  background-size: 60%;
}
.separateDiv {
  height: 300px;
}
h3 {
  font-size: 34px !important;
  color: #e4d9b8 !important;
  font-weight: 100 !important;
}
.MytournamentsCard {
  background-color: #141414 !important;
  color: #fff !important;
  padding: 30px;
}
.headerNavbar {
  margin-bottom: 180px;
}

.TopHeading {
  font-size: 70px !important;
  text-align: center;
  margin-top: 202px;
  margin-bottom: 170px;
  text-transform: uppercase;
  color: #e4d9b8;
}

.UpcomingTournamentsHeading,
.OngoingTournamentsHeading {
  font-size: 48px !important;
  color: #00ffb4;
  text-transform: uppercase;
  text-align: center;
  font-weight: 900 !important;
  padding-bottom: 74px !important;
}

.carosualImage {
  background-repeat: no-repeat;
  background-size: cover;
  width: 100% !important;
  height: 300px !important;
}

.TournamentsCarousal {
  border-radius: 10px 10px 0px 0px !important;
  width: 270px !important;
}

.CarousalContent {
  background-color: #292929;
  font-weight: 400;
  text-align: center;
  padding: 30px 0;
}

.TeamHeading {
  color: #ee6954;
}

.VsHeading {
  color: #e4d9b8;
}

.ResgisterButton {
  border-radius: 7px;
  color: #e4d9b8;
}

.OngoingTournamentsSections {
  margin-top: 160px;
}

.OngoingTournamentsCarousal {
  border-radius: 10px 10px 0px 0px !important;
  width: 300px !important;
}

.LoopVideoSection,
.WhomggforSection {
  padding: 10px 10px !important;
  margin-top: 180px;
  margin-bottom: 190px;
  height: auto;
  width: 1050px;
  border-radius: 10px;
  background-color: #292929;
}

.LoopVideoSection_heading,
.WhomggforSection_heading {
  color: #ee6954 !important;
  padding-left: 30px;
  font-weight: 800 !important;
}

.LoopVideoSection_video,
.WhomggforSection_video {
  height: 400px !important;
  width: 900px !important;
  background-color: rgb(113, 108, 108);
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.LoopVideoSection_content p span,
.WhomggforSection_content p span {
  font-weight: 700 !important;
}

.LoopVideoSection_content,
.WhomggforSection_content {
  font-size: 22px !important;
  margin-left: 30px;
  font-weight: lighter !important;
  margin-top: 50px;
  margin-bottom: 40px;
  word-spacing: 2px;
}

.WhomggforSection_content {
  padding-left: 60px !important;
}

.CoursesSection {
  margin-bottom: 120px;
  background-color: #292929;
  width: 750px;
  height: 380px;
}

.CoursesSection img {
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
}

.CoursesSection h3 {
  color: #ee6954 !important;
  font-size: 34px !important;
  font-weight: 800 !important;
}

.CoursesSection p {
  font-size: 22px !important;
  font-weight: lighter !important;
  word-spacing: 2px;
}

.ExploreButton button,
.JoinButton button {
  width: 200px !important;
  height: 50px;
  background-color: #5ce034 !important;
  color: #fff;
  font-size: 25px;
  font-weight: 700 !important;
}

.CreateOwnTeam {
  .CreateOwnTeam_content {
    background-color: #292929;
    width: 150px;
    height: 160px;
    border-radius: 50px;
    text-align: center;
    padding: 45px 0;

    h3 {
      color: #ee6954 !important;
      font-size: 34px !important;
      letter-spacing: 1.5px;
      font-weight: 900 !important;
    }

    p {
      font-size: 14px !important;
      font-weight: 300 !important;
      letter-spacing: 1px !important;
      word-spacing: 1px;
      color: #e4d9b8 !important;
    }
  }
}

.GlobalTournaments {
  .GlobalTournaments_content {
    background-color: #292929;
    width: 500px;
    height: 160px;
    border-radius: 50px;
    text-align: center;
    padding: 45px 0;
    margin-top: 30px !important;

    h3 {
      color: #ee6954 !important;
      font-size: 34px !important;
      letter-spacing: 1.5px;
      font-weight: 900 !important;
    }

    p {
      font-size: 14px !important;
      font-weight: 300 !important;
      letter-spacing: 1px !important;
      color: #e4d9b8 !important;
    }
  }
}

.Coaching {
  .Coaching_content {
    background-color: #292929;
    width: 500px;
    height: 160px;
    border-radius: 50px;
    text-align: center;
    padding: 45px 0;
    margin-top: 30px !important;

    h3 {
      color: #ee6954 !important;
      font-size: 34px !important;
      letter-spacing: 1.5px;
      font-weight: 900 !important;
    }

    p {
      font-size: 14px !important;
      font-weight: 300 !important;
      letter-spacing: 1px !important;
      color: #e4d9b8 !important;
    }
  }
}

.GGsection {
  .GGsection_content {
    background-color: #292929;
    width: 210px;
    height: 160px;
    border-radius: 50px;
    text-align: center;
    padding: 50px 0;
    margin-top: 30px;

    h3 {
      color: #ee6954 !important;
      font-size: 34px !important;
      letter-spacing: 1.5px;
      font-weight: 900 !important;
    }

    p {
      font-size: 14px !important;
      font-weight: 300 !important;
      letter-spacing: 1px !important;
      word-spacing: 1px;
      color: #e4d9b8 !important;
    }
  }
}

.JoinCommunitySection {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 400px;
  margin-bottom: 50px;

  .JoinCommunitySection_content {
    background-color: #292929;
    width: -webkit-fill-available;
    min-width: 100%;
    height: auto;
    padding: 30px !important;
    border-radius: 15px;

    h3 {
      color: #5ce034 !important;
      font-weight: 800 !important;
      margin-bottom: 20px;
      font-size: 34px !important;
    }

    img {
      width: 100%;
      margin: auto;
    }
  }
}

.footerDiv {
  width: 100%;
  background-color: #141414;
  margin-top: 140px;
  padding-top: 50px;
  padding-bottom: 40px;

  .CardStylesFooter {
    background-color: #141414;
  }

  .BlueColorlink {
    color: rgb(62, 62, 249) !important;
  }

  .fontFooterLink {
    font-family: poppins;
    font-size: 25px;
    font-weight: 1000;
    // text-transform: capitalize;
  }

  .fontFollow {
    font-family: poppins;
    font-size: 27px;
    font-weight: 900;
    color: #5ce034;
  }

  .fontEmail {
    font-family: poppins;
    font-size: 19px;
  }
}
.noTournamentDiv {
  height: 550px;
  align-items: center;
  display: flex;
  text-align: center;
}
.OnGoingSlider {
  .TournamentsCarousal {
    width: 100% !important;
    height: 500px;
  }
  .slick-slide {
    padding: unset;
  }
}
.desktopDisplay {
  display: block;
}
.mobileDisplay {
  display: none;
}
@media (max-width: 998px) {
  .UpcomingTournamentsHeading,
  .OngoingTournamentsHeading {
    padding-bottom: 10px !important;
  }
  .inputSearchField {
    width: 70% !important;
    height: 40px !important;
  }
}
@media (max-width: 600px) {
  .inputSearchField {
    width: 70% !important;
  }
}
@media (min-width: 641px) and (max-width: 1224px) {
  .MainTournamentPage1 {
    .JoinCommunitySection {
      margin-top: 10% !important;
    }
  }
}
@media (max-width: 767px) {
  .tournamentCard,
  .leaderBoardCard{
    margin: 20px;
  }
  .TournamentsCarousal {
    width: 70% !important;
    height: 500px;
  }
  .desktopDisplay {
    display: none;
  }
  .mobileDisplay {
    display: block;
  }
  .CarousalContent {
    padding: 36px 0;
  }
  .ResgisterButton {
    width: 50% !important;
  }
}
@media (max-width: 640px) {
  .JoinCommunitySection .JoinCommunitySection_content h3 {
    color: #5ce034 !important;
    font-weight: 800 !important;
    margin-bottom: 20px;
    font-size: 14px !important;
  }
  .JoinCommunitySection .JoinCommunitySection_content img {
    width: -webkit-fill-available;
    margin: auto;
  }
  .JoinCommunitySection .JoinCommunitySection_content {
    background-color: #292929;
    width: 100%;
    height: auto;
    padding: 30px !important;
    border-radius: 15px;
  }
}
@media (max-width: 640px) {
  .MainTournamentPage1 {
    .JoinCommunitySection {
      margin-top: unset !important;
    }
  }
  .TopHeading {
    margin-top: unset;
    font-size: 20px !important;
    margin-bottom: 10px;
  }
  .UpcomingTournamentsHeading,
  .OngoingTournamentsHeading {
    font-size: 20px !important;
    padding-bottom: unset !important;
  }
  .noTournamentDiv {
    font-size: 15px !important;
    white-space: noWrap;
    height: unset;
  }
  .JoinButton button {
    border-radius: 15px;
    font-size: 15px;
    width: 150px !important ;
  }
  .carosualImage {
    height: 250px !important;
  }
  .TournamentsCarousal {
    height: 450px !important;
  }
}
@media (min-width: 480px) and (max-width: 640px) {
  .MainTournamentPage {
    background-position-x: 143%;
    background-size: 85%;
  }
}
@media (max-width: 480px) {
  .MainTournamentPage,
  .MainTournamentPage1 {
    background-size: unset;
  }
}

@media (min-width: 1225px) and (max-width: 1335px) {
  .OnGoingSlider .TournamentsCarousal {
    width: 90% !important;
  }
}
@media (min-width: 641px) and (max-width: 868px) {
  .TopHeading {
    font-size: 50px !important;
  }
}

// new tournament registration
.TeamRegModal {
  .modal-content .modal-body {
    padding: 0px !important;
    display: flex;
    justify-content: center;
  }
}
.tournamentCard {
  height: 700px;
  // margin-right: 22px;
  // margin-left: 21px;
  background-color: #2a8e62;
  margin-bottom: 50px;
  .cardHeader {
    color: #fff;
    font-size: 25px;
    font-weight: 900;
    cursor: pointer;
    padding: 23px 22px;
    // svg {
    //   width: 100%;
    // }
  }
  .CardHeaderParent {
    background-color: #6ba56b;
  }
  .fixtureImage {
    width: auto;
    height: 80%;
    object-fit: contain;
  }
}
.cardbackgroungColor {
  background-color: #2a8e62 !important;
}
.mobileDisplayDetails {
  display: inline;
}
@media (max-width: 768px) {
  .mobileDisplayDetails {
    display: none;
  }
}
// tournament registration
.thumbnailImage {
  width: 100%;
  object-fit: fill;
  opacity: 0.6;
  position: relative;
  height: 1000px;
}
.imageText {
  position: relative;
  top: -660px;
  color: #fff;
  .price {
    font-size: 80px;
    margin: 48px 0px;
    font-family: poppins;
  }
  .imageButton {
    background-color: #70a070;
    padding: 20px;
    font-size: 36px;
    font-weight: 900;
    border-radius: 15px;
  }

  .tournamentName {
    font-size: 70px;
    // white-space: nowrap;
    flex-wrap: nowrap;
    font-family: poppins;

  }
  .DateDiv {
    font-size: 35px;
    position: relative;
    top: 10px;
    font-weight: 900;
    white-space: nowrap;
    flex-wrap: nowrap;
  }
}
.fontText {
  color: #fff;
  // text-transform: uppercase;
}
// tournament last
.congratulationDiv {
  padding: 100px 10px;
  h1 {
    font-size: 80px;
    line-height: 100px;
    letter-spacing: 1px;
    font-family: futuraFont;
  }
}
.headerText {
  text-align: center;
  h4 {
    letter-spacing: 1px;
    font-size: 30px;
    line-height: 30px;
  }
  h1 {
    letter-spacing: 2px;
    font-size: 80px;
    font-family: futuraFont;
  }
}
.LastTournamentCardParent {
  margin: 50px 0px;
  margin-bottom: 130px !important;
}
.LastTournamentCard {
  height: 400px;
  border-radius: 15px;
}
.buttonscss {
  margin: 50px 0px;
  button {
    background-color: #6ba56b;
    color: #fff;
    font-size: 40px;
    font-weight: 900;
    border-radius: 10px;
    padding: 20px;
    cursor: pointer;
  }
}

// Team Registration
.TeamRegSubParentDiv {
  margin-bottom: 100px;
}
.parentDivTeamReg {
  margin: 50px 10px;
}
.parentDiv {
  margin: 40px 10px;
}
.parentDiv,
.parentDivTeamReg {
  .labelName {
    color: #fff;
    font-size: 30px;
    white-space: nowrap;
    font-weight: 900;
    width: 352px;
  }
  .inputStyle {
    background-color: #fff !important;
    border-radius: 25px;
    margin-left: 20px;
    // text-transform: capitalize;
    color: #000 !important;
    font-size: 15px;
  }
  .inputStyleLogo {
    width: 300px;
    height: 269px;
    background-color: #fff !important;
    border-radius: 25px;
    margin-left: 57px;
    position: relative;
  }
  .AddIcon {
    margin-top: 10px;
    margin-left: 20px;
    position: relative;
    bottom: 61px;
    left: 286px;
    border-radius: 0px 0px 26px 0px;
  }
}
.registerNowBtn {
  background-color: #fff;
  color: #000;
  border: none;
  font-size: 40px;
  font-weight: 900;
  border-radius: 25px;
  padding: 15px 68px;
}
.teamRegistrationCard {
  height: auto;
  background-color: #2a8e62;
  margin-bottom: 130px;
  min-width: 1100px;
  display: block;
  align-items: center;
  justify-content: center;
  position: relative;

  .cardHeader {
    color: #fff;
    font-size: 25px;
    font-weight: 900;
    cursor: pointer;
    padding: 23px 22px;
  }
  .CardHeaderParent {
    background-color: #6ba56b;
  }
  .teamRegCursor {
    position: absolute;
    right: 0px;
    margin-right: 19px;
    margin-top: 31px !important;
  }
}
.cardbackgroungColor {
  background-color: #2a8e62 !important;
}
.addBtn {
  background-color: #fff;
  color: #000;
  border: none;
  font-size: 40px;
  font-weight: 900;
  border-radius: 25px;
  padding: 7px 40px;
}
.resetBtn {
  background-color: #119d5e;
  color: #fff;
  border: none;
  font-size: 40px;
  font-weight: 900;
  border-radius: 25px;
  padding: 7px 40px;
}

.BackNextBtn {
  display: flex;
  justify-content: space-between;
  position: relative;
  // top: -88px;
}
.TeamHeader,
.TeamBody {
  color: #fff;
  font-size: 25px;
  text-align: center;
  padding: 10px;
  margin: 5px 54px;
}
.TeamBody {
  background-color: #fff;
  color: #000;
  margin-bottom: 30px;
  position: relative;
  img {
    position: absolute;
    left: -18px;
    border-radius: 50%;
    width: 70px;
    height: 70px;
    top: -6px;
    border: 1px solid #1b1919;
  }
}
.parentDivDeatils {
  padding-left: 40px;
  padding-top: 45px;
}
.Heading,
.content {
  font-size: 25px;
  color: #fff;
  // text-transform: capitalize;
}
.content {
  font-weight: 900;
  padding-left: 10px;
}
.PoolPriceImg {
  object-fit: contain;
  min-height: 300px;
}
// view tournament user side
.viewBtn {
  font-size: 50px;
  background-color: #01ffb3;
  color: #000;
  font-weight: 1000;
  padding: 10px 20px;
  border-radius: 15px;
}
.GridContentStyle1 {
  display: grid;
  // grid-template-columns: 1fr 1fr;
  grid-column: 3 3 3;
  height: 302px;
  overflow: auto;
  margin: 77px;
  /* border: 1px solid; */
  padding: 20px;
}
.confirm{
  h1{
    font-family: poppins;
  }
}
@media (min-width:1300px){
  .GridContentStyle {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column: 3 3 3;
    height: 302px;
    overflow: auto;
    margin: 77px;
    /* border: 1px solid; */
    padding: 20px;
  }
}
@media (min-width: 1300px) and (max-width: 1500px) {
  .tournamentCard {
    .cardHeader {
      svg {
        width: 30%;
        padding-left: 10px;
      }
    }
    .Heading,
    .content {
      font-size: 20px;
    }
  }
}
@media (min-width: 1080px) and (max-width: 1300px) {
  .confirm{
    h1{
      font-size: 25px !important;
      line-height: 25px !important;
    }
    svg{
      width: 13px;
    }
  }
  // team registration
  .teamRegistrationCard {
    left: unset;
    min-width: auto;
    .cardHeader {
      font-size: 18px;
    }
    .teamRegCursor {
      margin-right: 8px;
      margin-top: 9px !important;
      width: 15px;
    }
  }
  .parentDiv,
  .parentDivTeamReg {
    margin: 10px 0px;
    .labelName {
      font-size: 18px;
    }
    .inputStyle {
      margin-left: unset;
      font-size: 13px;
    }
  }
  .RequiredField {
    font-size: 16px;
    padding-left: 10px;
  }
  .addBtn,
  .resetBtn {
    font-size: 18px;
    padding: 5px 50px;
  }
  .BackNextBtn {
    justify-content: center;
  }
  .parentDivTeamReg {
    .inputStyleLogo {
      width: 200px;
      height: 176px;
      margin-top: 10px;
      margin-left: unset;
    }
    .AddIcon {
      left: 130px;
    }
  }
  .registerNowBtn {
    font-size: 16px;
    padding: 5px 43px;
  }
  .tournamentCard {
    .cardHeader {
      font-size: 21px;
      svg {
        width: 30%;
        padding-left: 10px;
      }
    }
    .Heading,
    .content {
      font-size: 20px;
    }
  }
}
@media (min-width: 768px) and (max-width: 1080px) {
  .confirm{
    h1{
      font-size: 20px !important;
      line-height: 20px !important;
    }
    svg{
      width: 13px;
    }
  }
  // tournament registration
  .thumbnailImage {
    height: 550px;
  }
  .imageText {
    top: -324px;
    .tournamentName {
      font-size: 40px;
    }
    .DateDiv {
      font-size: 20px;
      left: -4%;
    }
    .price {
      font-size: 44px;
      margin: 30px 0px;
    }
    .imageButton {
      padding: 25px 30px;
      font-size: 30px;
    }
  }
  .fontText {
    h1 {
      line-height: 10px;
      font-size: 50px;
    }
  }
  // congratulation page
  .congratulationDiv,
  .headerText {
    h1 {
      font-size: 55px;
      line-height: 55px;
    }
  }
  .LastTournamentCard {
    height: 250px;
  }
  .headerText {
    padding: 0px 68px;
  }
  // team registration
  .teamRegistrationCard {
    left: unset;
    min-width: auto;
    .cardHeader {
      font-size: 18px;
    }
    .teamRegCursor {
      margin-right: 8px;
      margin-top: 9px !important;
      width: 15px;
    }
  }
  .parentDiv,
  .parentDivTeamReg {
    margin: 10px 0px;
    .labelName {
      font-size: 18px;
    }
    .inputStyle {
      margin-left: unset;
      font-size: 13px;
    }
  }
  .RequiredField {
    font-size: 16px;
    padding-left: 10px;
  }
  .addBtn,
  .resetBtn {
    font-size: 18px;
    padding: 5px 50px;
  }
  .BackNextBtn {
    justify-content: center;
  }
  .parentDivTeamReg {
    .inputStyleLogo {
      width: 200px;
      height: 176px;
      margin-top: 10px;
      margin-left: unset;
    }
    .AddIcon {
      left: 130px;
    }
  }
  .registerNowBtn {
    font-size: 16px;
    padding: 5px 43px;
  }
  .tournamentCard {
    height: auto;
    .cardHeader {
      padding: 2px 2px;
      font-size: 16px;
      svg {
        width: 31%;
        padding-left: 10px;
      }
    }
    .Heading,
    .content {
      font-size: 20px;
    }
    .parentDivDeatils {
      padding-top: 20px;
    }
    .ParentDetailsCard {
      margin-bottom: 50px;
    }
  }
}
@media (min-width: 640px) and (max-width: 768px) {
  .confirm{
    h1{
      font-size: 20px !important;
      line-height: 20px !important;
    }
    svg{
      width: 13px;
    }
  }
  // tournament registration
  .thumbnailImage {
    height: 450px;
  }
  .imageText {
    top: -69px;
    .tournamentName {
      font-size: 50px;
    }
    .DateDiv {
      font-size: 25px;
      left: -4%;
    }
    .price {
      font-size: 44px;
      margin: 30px 0px;
    }
    .imageButton {
      padding: 15px;
      font-size: 26px;
    }
  }
  .fontText {
    h1 {
      line-height: 10px;
      font-size: 50px;
    }
  }
  // congratulation page
  .congratulationDiv,
  .headerText {
    h1 {
      font-size: 55px;
      line-height: 55px;
    }
  }
  .headerText {
    h4 {
      font-size: 30px;
      padding: 0px 10px;
    }
  }
  .LastTournamentCard {
    height: 250px;
    margin-bottom: 30px !important;
    margin: 0px 20px;
  }
  .buttonscss {
    button {
      font-size: 24px;
    }
  }
  // team registration
  .teamRegistrationCard {
    left: unset;
    min-width: auto;
    .cardHeader {
      font-size: 13px;
    }
    .teamRegCursor {
      margin-right: 8px;
      margin-top: 9px !important;
      width: 15px;
    }
  }
  .parentDiv,
  .parentDivTeamReg {
    margin: 10px 0px;
    .labelName {
      font-size: 16px;
    }
    .inputStyle {
      margin-left: unset;
      font-size: 13px;
    }
  }
  .RequiredField {
    font-size: 13px;
    padding-left: 10px;
  }
  .addBtn,
  .resetBtn {
    font-size: 18px;
    padding: 5px 50px;
  }
  .BackNextBtn {
    justify-content: center;
  }
  .parentDivTeamReg {
    .inputStyleLogo {
      width: 200px;
      height: 176px;
      margin-top: 10px;
      margin-left: unset;
    }
    .AddIcon {
      left: 130px;
    }
  }
  .registerNowBtn {
    font-size: 16px;
    padding: 5px 43px;
  }
  .tournamentCard {
    height: auto;
    .cardHeader {
      padding: 2px 2px;
      svg {
        width: 79%;
        padding-left: 10px;
      }
    }
    .Heading,
    .content {
      font-size: 20px;
    }
    .parentDivDeatils {
      padding-top: 20px;
    }
    .ParentDetailsCard {
      margin-bottom: 50px;
    }
  }
}

@media (min-width: 480px) and (max-width: 640px) {
  .confirm{
    h1{
      font-size: 20px !important;
      line-height: 20px !important;
    }
    svg{
      width: 13px;
    }
  }
  // tournament registration
  .thumbnailImage {
    height: 350px;
  }
  .imageText {
    top: -69px;
    .tournamentName {
      font-size: 45px;
    }
    .DateDiv {
      font-size: 25px;
      left: -4%;
    }
    .price {
      font-size: 44px;
      margin: 30px 0px;
    }
    .imageButton {
      padding: 15px;
      font-size: 26px;
    }
  }
  .fontText {
    h1 {
      line-height: 10px;
      font-size: 37px;
    }
  }
  .tournamentCard {
    height: auto;
    .cardHeader {
      padding: 2px 2px;
      svg {
        width: 69%;
        padding-left: 10px;
      }
    }
    .Heading,
    .content {
      font-size: 14px;
    }
    .parentDivDeatils {
      padding-top: 15px;
    }
    .ParentDetailsCard {
      margin-bottom: 50px;
    }
  }
  // congratulation page
  .congratulationDiv,
  .headerText {
    h1 {
      font-size: 45px;
      line-height: 46px;
    }
  }
  .headerText {
    h4 {
      font-size: 25px;
      padding: 0px 10px;
    }
  }
  .LastTournamentCard {
    height: 250px;
    margin-bottom: 30px !important;
    margin: 0px 20px;
  }
  .buttonscss {
    button {
      font-size: 24px;
    }
  }
  // team registration
  .teamRegistrationCard {
    left: unset;
    min-width: auto;
    .cardHeader {
      font-size: 13px;
    }
    .teamRegCursor {
      margin-right: 8px;
      margin-top: 9px !important;
      width: 15px;
    }
  }
  .parentDiv,
  .parentDivTeamReg {
    margin: 10px 0px;
    .labelName {
      font-size: 16px;
    }
    .inputStyle {
      margin-left: unset;
      font-size: 13px;
    }
  }
  .RequiredField {
    font-size: 13px;
    padding-left: 10px;
  }
  .addBtn,
  .resetBtn {
    font-size: 18px;
    padding: 5px 50px;
  }
  .BackNextBtn {
    justify-content: center;
  }
  .parentDivTeamReg {
    .inputStyleLogo {
      width: 200px;
      height: 176px;
      margin-top: 10px;
      margin-left: unset;
    }
    .AddIcon {
      left: 130px;
    }
  }
  .registerNowBtn {
    font-size: 16px;
    padding: 5px 43px;
  }
  .tournamentCard {
    height: auto;
    .cardHeader {
      padding: 2px 2px;
      svg {
        width: 79%;
        padding-left: 10px;
      }
    }
    .Heading,
    .content {
      font-size: 18px;
    }
    .parentDivDeatils {
      padding-top: 15px;
    }
    .ParentDetailsCard {
      margin-bottom: 50px;
    }
  }
}
@media (min-width: 380px) and (max-width: 480px) {
  .confirm{
    h1{
      font-size: 18px !important;
      line-height: 18px !important;
    }
    svg{
      width: 15px;
    }
  }
  // tournament registration
  .thumbnailImage {
    height: 350px;
  }
  .imageText {
    top: -69px;
    .tournamentName {
      font-size: 33px;
    }
    .DateDiv {
      font-size: 20px;
      left: -4%;
    }
    .price {
      font-size: 36px;
      margin: 9px 0px;
    }
    .imageButton {
      padding: 7px;
      font-size: 18px;
    }
  }
  .fontText {
    h1 {
      line-height: 0px;
      font-size: 30px;
    }
  }
  .tournamentCard {
    height: auto;
    .cardHeader {
      padding: 2px 2px;
      svg {
        width: 69%;
        padding-left: 10px;
      }
    }
    .Heading,
    .content {
      font-size: 14px;
    }
    .parentDivDeatils {
      padding-top: 15px;
    }
    .ParentDetailsCard {
      margin-bottom: 50px;
    }
  }
  // congratulation page
  .congratulationDiv,
  .headerText {
    h1 {
      font-size: 37px;
      line-height: 46px;
    }
  }
  .headerText {
    h4 {
      font-size: 25px;
      padding: 0px 10px;
    }
  }
  .LastTournamentCard {
    height: 250px;
    margin-bottom: 30px !important;
    margin: 0px 20px;
  }
  .buttonscss {
    button {
      font-size: 24px;
    }
  }
  // team registration
  .teamRegistrationCard {
    left: unset;
    min-width: auto;
    .cardHeader {
      font-size: 13px;
      padding: 8px 15px;
    }
    .teamRegCursor {
      margin-right: 8px;
      margin-top: 9px !important;
      width: 15px;
    }
  }
  .parentDiv,
  .parentDivTeamReg {
    margin: 10px 0px;
    .labelName {
      font-size: 16px;
    }
    .inputStyle {
      margin-left: unset;
      font-size: 13px;
    }
  }
  .RequiredField {
    font-size: 13px;
    padding-left: 10px;
  }
  .addBtn,
  .resetBtn {
    font-size: 18px;
    padding: 5px 50px;
  }
  .BackNextBtn {
    justify-content: center;
  }
  .parentDivTeamReg {
    .inputStyleLogo {
      width: 200px;
      height: 176px;
      margin-top: 10px;
      margin-left: unset;
    }
    .AddIcon {
      left: 130px;
    }
  }
  .registerNowBtn {
    font-size: 16px;
    padding: 5px 43px;
  }
  .tournamentCard {
    height: auto;
    .cardHeader {
      padding: 2px 2px;
      svg {
        width: 69%;
        padding-left: 10px;
      }
    }
    .Heading,
    .content {
      font-size: 16px;
    }
    .parentDivDeatils {
      padding-top: 15px;
    }
    .ParentDetailsCard {
      margin-bottom: 50px;
    }
  }
}
@media (max-width: 380px) {
  .confirm{
    h1{
      font-size: 15px !important;
      line-height: 15px !important;
    }
    svg{
      width: 12px;
    }
  }
  // congratulation page
  .congratulationDiv,
  .headerText {
    h1 {
      font-size: 37px;
      line-height: 46px;
    }
  }
  .headerText {
    h4 {
      font-size: 23px;
      padding: 0px 10px;
    }
  }
  .LastTournamentCard {
    height: 250px;
    margin-bottom: 30px !important;
    margin: 0px 20px;
  }
  .buttonscss {
    button {
      font-size: 15px;
    }
  }
  // tournament registration
  .thumbnailImage {
    height: 350px;
  }
  .imageText {
    top: -69px;
    .tournamentName {
      font-size: 33px;
    }
    .DateDiv {
      font-size: 20px;
      left: -4%;
    }
    .price {
      font-size: 36px;
      margin: 9px 0px;
    }
    .imageButton {
      padding: 7px;
      font-size: 18px;
    }
  }
  .fontText {
    h1 {
      line-height: 10px;
      font-size: 25px;

    }
  }
  .tournamentCard {
    height: auto;
    .cardHeader {
      padding: 2px 2px;
      svg {
        width: 69%;
        padding-left: 10px;
      }
    }
    .Heading,
    .content {
      font-size: 14px;
    }
    .parentDivDeatils {
      padding-top: 15px;
    }
    .ParentDetailsCard {
      margin-bottom: 50px;
    }
  }

  //team registration
  .teamRegistrationCard {
    min-width: auto;
    left: unset;
    .cardHeader {
      font-size: 13px;
    }
    .teamRegCursor {
      margin-right: 8px;
      margin-top: 9px !important;
      width: 15px;
    }
  }
  .parentDiv,
  .parentDivTeamReg {
    margin: 10px 0px;
    .labelName {
      font-size: 16px;
    }
    .inputStyle {
      margin-left: unset;
      font-size: 13px;
    }
  }
  .RequiredField {
    font-size: 13px;
    padding-left: 10px;
  }
  .addBtn,
  .resetBtn {
    font-size: 18px;
    padding: 5px 50px;
  }
  .BackNextBtn {
    justify-content: center;
  }
  .parentDivTeamReg {
    .inputStyleLogo {
      width: 200px;
      height: 176px;
      margin-top: 10px;
      margin-left: unset;
    }
    .AddIcon {
      left: 130px;
    }
  }
  .registerNowBtn {
    font-size: 16px;
    padding: 5px 43px;
  }
}
// search
.inputSearchField {
  width: 28%;
  height: 50px;
  margin-left: 20px;
  font-size: 15px;
  color: #fff;
}
.leaderBoardCard {
  height: 700px;
  background-color: #298e61;
  .LeaderBoardHeader {
    color: #fff;
    font-size: 25px;
    font-weight: 1000;
    // font-family: Poppins;
    background-color: #6ba56b;
    .LeaderBoardHeaderC1 {
      padding: 20px 80px;
    }
  }
}
.cardbackgroungColorLeaderboard {
  background-color: #298e61;
}
@media (max-width: 360px) {
  .TournamentsCarousal,
  .OnGoingSlider {
    margin-top: 20px;
  }
}
@media (max-width: 600px) {
  .OnGoingSlider {
    .slick-next {
      right: 62px;
      z-index: 999;
      top: 58%;
    }
    .slick-prev {
      left: 25px;
      z-index: 999;
      top: 58%;
    }
    .slick-next:before,
    .slick-prev:before {
      font-size: 51px;
    }
  }
  .TeamHeading{
    font-size: 20px !important;
  }
}
@media (min-width:481px) and (max-width:640px){
  .NoTaggedVideos{
    span,h3,h4{
    font-size: 18px !important;
  }}
  .leaderBoardCard{
    .LeaderBoardHeader{
      font-size: 18px;
      .LeaderBoardHeaderC1{
        padding: 20px 83px;
      }
    }
  }
  .viewBtn{
    font-size: 20px;
  }
}
@media (min-width:380px) and (max-width:480px){
  .NoTaggedVideos{
    span,h3,h4{
    font-size: 15px !important;
  }}
  .leaderBoardCard{
    .LeaderBoardHeader{
      font-size: 15px;
      .LeaderBoardHeaderC1{
        padding: 20px 65px;
      }
    }
  }
  .viewBtn{
    font-size: 17px;
  }
}
@media (max-width:380px){
  .NoTaggedVideos{
    span,h3,h4{
    font-size: 15px !important;
  }}
  .leaderBoardCard{
    .LeaderBoardHeader{
      font-size: 15px;
      .LeaderBoardHeaderC1{
        padding: 20px 53px;
      }
    }
  }
  .viewBtn{
    font-size: 15px;
  }
}
@media (max-width:414px){
  .modal.show .modal-dialog {
    margin-right: 24px;
}
}