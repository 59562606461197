.ProductCard{
    width: 90%;
    height: 600px;
    background: rgb(14, 14, 14) !important;
    margin: 20px;
    h4{
        color: rgb(50, 191, 158) !important; 
        text-align: center;

    }
    p{
        color: #fff !important;
        font-size: 16px;
        text-align: center;
    }
    h5{
        color: rgb(72, 190, 37);
        text-align: center;
        font-size: 25px;
    }
    .ProductButton{
        background-color: blue;
        color: #fff;
        font-weight: 700;
        padding: 10px 40px;
        text-align: center;
        display: flex;
        justify-content: center;
    }
    .productDetailsButton{
        background-color: rgb(185, 185, 204);
        color: rgb(14, 13, 13);
        font-weight: 700;
        padding: 10px 20px;
        text-align: center;
        display: flex;
        justify-content: center;
    }
}