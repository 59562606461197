.LoginPage {
  position: relative;

  .slick-next:before,
  .slick-prev:before {
    font-size: 42px;
  }

  // .OGGGDiv {
  //   margin-left: 10px;
  // }

  .CardStyles {
    height: 280px;
    background: linear-gradient(90deg, #0e0e0e 0% 100%);
  }

  .spanTextExclusiveTextStyle {
    line-height: 44px;
    text-align: center;
    color: #fff;
    font-weight: 300 !important;
  }

  .spanCardPadding {
    width: 50%;
    padding: 24px;
  }

  .CardStylesFooter {
    padding: 70px;
    background-color: #151515 !important;
    margin-bottom: 20px;
  }

  .spanComingFontStyles {
    line-height: 68px;
    text-align: center;
    color: #00ffb4;
  }

  .spanChoose1TextStyle {
    text-align: center;
    color: #f3f3f3;
  }

  .spanChooseFontStyles {
    text-align: center;
    color: #00ffb4;
  }

  .textStyles {
    color: #00ffb4 !important;
    display: flex;
    justify-content: center;
  }

  .TextPadding {
    padding: 20px;
  }

  .CardBorderRadius {
    border-radius: 25px;
  }

  .CardStyles {
    img {
      border-radius: 50% !important;
    }
  }

  .CarosuelVideoStyle1 {
    width: 98%;
    margin: 20px;
    height: 243px;
    background: #0c0a0a;
    align-items: center;
    border-radius: 10px;
  }

  .CarosuelVideoStyle {
    margin: 20px;
    height: 248px;
    background: #f3f3f3;
    align-items: center;
    border-radius: 50%;
  }

  .HeaderImage {
    padding-top: 60px;
  }

  .namePosition {
    margin-top: 20px !important;
  }

  .namePosition1 {
    margin-top: 20px !important;
  }


}

.RegistrationPage,
.UserLoginPage {
  .imageLogoPosLogin {
    position: relative;
    top: -25px;
    left: -179px !important;
  }

  .logoImage {
    width: 130px;
    height: 100px;
  }

  .imageLogoPos {
    position: relative;
    top: -25px;
    left: -164px;
  }

  .InputDiv {
    padding: 5px 2px;
  }

  .textStylesLogin {
    color: #5cdf34;
    padding-right: 5px !important;
  }

  .alignItemPopUp {
    position: relative;
    right: -139px;
  }

  .faceBookIcon {
    position: relative;
    left: 39px;
  }

  .GoogleIcon {
    // margin-top: 20px;
    font-size: 45px;
  }
}
@media (min-width:992px) and (max-width:1300px){
  .RowPadding{
    margin-top: 13px;
  }
}
@media (min-width: 900px) {
  .LoginPage {
    .ChooseGameDiv {
      margin-top: 30px !important;
    }
  }
}

@media (min-width: 1300px) {
  .LoginPage {
    .ChooseGameDiv {
      margin-top: 100px;
    }
  }

  .UserLoginPage {
    .alignItemPopUpLogin {
      position: relative;
      right: -182px;
      top: -10px;
    }
  }

  .RegistrationPage,
  .UserLoginPage {
    .GoogleIcon {
      margin-top: 16px;
      font-size: 45px;
    }

    .logoImage {
      width: 86px !important;
      height: 64px !important;
    }

    .DiscordIcon {
      width: 45px !important;
      margin-top: 17px;
      position: relative;
      height: 45px !important;
    }

    .faceBookIcon {
      font-size: 72px;
      visibility: visible !important;
      position: relative;
      top: -17px;
    }

    .iconCard {
      margin-top: -28px !important;
      margin: 0px -18px;
      background: #11110d !important;
      height: 82px;
    }

    .marginTopOr {
      margin-top: 20px !important;
    }

    .form input {
      height: unset !important;
    }

    .form button {
      height: unset !important;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .InputDiv {
      padding: 5px 66px;
    }

    .spanHeading {
      font-size: 25px !important;
      margin-top: 20px;
      padding-top: 10px !important;
      margin-bottom: 20px !important;
    }

    .formLoginCard {
      padding: 30px !important;
      margin: -16px !important;
    }

    .imageLogoPos {
      left: -190px !important;
    }

    .alignItemPopUp {
      right: -175px !important;
      top: -20px;
    }

    .fontFuturaMedium207 {
      font-size: 20px;
    }

    .FontLoto155 {
      font-size: 13px;
    }

    .HeaderImage {
      padding-top: 10px;
    }
  }
}

.CardStylesDetails {
  height: 400px;
  background-color: #0f0e0e !important;
  margin-bottom: 20px;
}

.FooterCard {
  height: 600px;
  background-color: #0f0e0e !important;
}

.FooterDiv {
  margin-top: 400px;
}

.videoPos {
  position: relative;
  top: 100px;
}

.videoHeader {
  width: 150px;
}

.CardBorder {
  width: 150px;
  height: 150px;
  background-color: #252323 !important;
  display: flex;
  justify-content: center;
  padding: 20px;
  margin: 62px;
  border-radius: 50% !important;
}

.widthName {
  width: 180px;
}

.widthName1 {
  width: 230px;
  // margin-left: 40px;
  margin-top: 40px;
}

.slick-slide {
  padding: 30px;
}

.login {
  // background: black;
  // width: 360px;
  // padding: 8% 0 0;
  margin: auto;
  font-family: "Lato", sans-serif;
}

.form {
  background: rgb(34,193,195) !important;
  background: linear-gradient(
    81deg,
    #202e44 30%,
    rgb(161 135 80) 100%
  ) !important; // background: #1e1c1c !important;
  position: relative;
  z-index: 1;
  background: #ffffff;
  border-radius: 10px;
  // max-width: 360px;
  margin: 0 auto 100px;
  padding: 45px;
  text-align: center;
}

.form input {
  outline: 0;
  background: #232323;
  color: #a99898;
  width: 100%;
  border: 0;
  border-radius: 5px;
  margin: 0 0 15px;
  padding: 15px;
  box-sizing: border-box;
  font-size: 14px;
  font-family: "Lato", sans-serif;
}

.form input:focus {
  background: #dbdbdb;
}

.form .LoginButton,
.form .signUPButton {
  font-family: "Lato", sans-serif;
  text-transform: uppercase;
  outline: 0;
  background: linear-gradient(81deg, #323336 30%, rgb(161, 135, 80) 100%);
  width: 100%;
  border: 0;
  border-radius: 5px;
  padding: 15px;
  color: #ffffff;
  font-size: 14px;
  -webkit-transition: all 0.3 ease;
  transition: all 0.3 ease !important;
  cursor: pointer;
}

.form button:active {
  background: #395591;
}

.form span {
  font-size: 17px;
  color: #d8dadd;
  margin-bottom: 25px;
  display: block;
  font-family: "Lato", sans-serif;
  font-style: normal;
}

.form .spanHeading {
  font-size: 27px;
  color: #d8dadd;
  margin-bottom: 25px;
  display: block;
  font-family: "Lato", sans-serif;
  font-style: normal;
}

.form p.error {
  margin: 0 0 10px 10px;
  text-align: left;
  font-size: 10px;
  color: red;
}

.formLoginCard {
  background: #111111;
  padding: 49px;
  margin: 20px;
}

.iconCard {
  margin-top: -28px !important;
  margin: 0px 20px;
  /* padding: 0px 18px; */
  background: #302c2c !important;
  height: 74px;
}

.imageLogoPos {
  position: relative;
  top: -25px;
  left: -274px;
}

.faceBookIcon {
  font-size: 50px;

  path {
    fill: white;
  }
}

.DiscordIcon {
  .social-svg-icon {
    path {
      fill: #383232;
    }
  }

  .social-svg-mask {
    path {
      fill: white;
    }
  }
}

.horizentalLine {
  color: rgb(39 160 151);
  height: 3px !important;
  display: flex;
  margin: 0 20%;
  margin-top: 20px;
}

.faIconsStyles {
  font-size: 30px;
  color: #342f2f !important;
}

.textposition {
  position: relative;
  top: 30%;
}

.verifyButton {
  background: linear-gradient(81deg,
      #323336 30%,
      rgb(161, 135, 80) 100%) !important;
  width: 100%;
  width: 150px;
}

.form .ForgetButton {
  width: 100%;
  cursor: pointer;
  // background-color: #202e44 !important;
}

.form .SubmitButton {
  font-family: "Lato", sans-serif;
  text-transform: uppercase;
  outline: 0;
  background: linear-gradient(27deg, #455ca1 30%, rgb(8 7 51) 100%) !important;
  border: 0;
  border-radius: 5px;
  padding: 15px;
  color: #ffffff;
  font-size: 14px;
  -webkit-transition: all 0.3 ease;
  transition: all 0.3 ease !important;
  cursor: pointer;
}

.RegistrationModal {
  .modal-content {
    background: none !important;

    .modal-body {
      padding: 0px !important;
    }
  }
}

.cursor-pointer {
  cursor: pointer;
}

.kep-login-facebook {
  visibility: hidden;
  background-color: #302c2c;
  border: calc(0.06887vw + 0.67769px) solid #302c2c;
  padding: calc(0.34435vw + 13.38843px) calc(0.34435vw + 18.38843px);
}

.faceBookIcon {
  font-size: 50px;
  visibility: visible !important;
  position: relative;
  top: -19px;
}

.alignItemPopUpLogin {
  position: relative;
  right: -122px;
  top: -10px;
}

// mobile break

@media (min-width: 640px) and (max-width: 992px) {
  .imageLogoPosLogin {
    position: relative;
    top: -25px;
    left: -179px !important;
  }

  .UserLoginPage {
    .alignItemPopUpLogin {
      position: relative;
      right: -107px;
      top: -10px;
    }

  }

  .RegistrationPage,
  .UserLoginPage {
    .GoogleIcon {
      margin-top: -7px;
      font-size: 40px;
    }

    .RowPadding {
      padding: 20px;
    }

    .logoImage {
      width: 100px !important;
      height: 70px !important;
    }

    .DiscordIcon {
      width: 40px !important;
      margin-top: -7px;
      position: relative;
      height: 40px !important;
    }

    .faceBookIcon {
      font-size: 65px;
      visibility: visible !important;
      position: relative;
      top: -37px;
    }

    .iconCard {
      margin-top: -28px !important;
      margin: 0px -17px;
      background: #111111 !important;
      height: 64px;
    }

    .marginTopOr {
      margin-top: 20px !important;
    }

    .form input {
      height: unset !important;
    }

    .form button {
      height: unset !important;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .InputDiv {
      padding: 16px 62px;
    }

    .spanHeading {
      font-size: 25px !important;
      margin-top: 20px;
      padding-top: 10px !important;
      margin-bottom: 20px !important;
    }

    .formLoginCard {
      padding: unset !important;
      margin: -16px !important;
    }

    .imageLogoPos {
      left: -186px !important;
    }

    .alignItemPopUp {
      right: -190px !important;
      top: -20px;
    }

    .fontFuturaMedium207 {
      font-size: 15px;
    }

    .FontLoto155 {
      font-size: 13px;
    }
  }

  .LoginPage {
    .ChooseGameDiv {
      margin-top: 150px !important;
    }

    .TextPadding {
      padding: 10px;
    }

    .fontRoboto577 {
      font-size: 35px !important;
    }



    // .HeaderImage {
    //   height: 400px !important;
    //   padding-top: 120px !important;
    // }
    .spanHeaderButton {
      padding: 6px 31px !important;
      margin: unset !important;

      svg {
        width: 17px;
      }
    }

    .fontRoboto254 {
      font-size: 20px !important;
    }

    .fontRoboto357 {
      font-size: 20px !important;
    }

    .spanTextExclusiveTextStyle {
      line-height: unset !important;
    }

    .CardStylesFooter {
      padding: 24px;
      padding-bottom: 40px !important;
    }

    .fontFuturaMedium207 {
      font-size: 11px !important;
    }

    .fontFuturaMedium157 {
      font-size: 10px !important;
    }

    // .faIconsStyles {
    //   font-size: 17px;
    // }
    .fafaIconsRow {
      padding: 0px 115px;
    }

    .spanComingFontStyles {
      line-height: unset !important;
    }

    // .CommingSoonDiv {
    //   margin: 40px 0px;
    // }
    .fontSamsung457 {
      font-size: 35px;
    }

    .fontSamsung257 {
      font-size: 20px;
    }
  }
}

@media (min-width: 898px) and (max-width: 992px) {

  .RegistrationPage,
  .UserLoginPage {
    .FontLoto155 {
      font-size: 12px !important;
    }
  }

  .LoginPage {
    .ChooseGameDiv {
      margin-top: 130px !important;
    }

    .fontLato355 {
      font-size: 18px !important;
    }

    .fontLato203 {
      font-size: 15px !important;
    }

    .CardStylesGGOG {
      height: 233px !important;
    }

    .widthName1 {
      width: 170px !important;
    }
  }
}

@media (min-width: 360px) and (max-width: 868px) {
  .fontLato355 {
    font-size: 25px !important;
  }

  .fontLato203 {
    font-size: 20px !important;
  }

  .CardStylesGGOG {
    height: 233px !important;
  }

  .widthName1 {
    width: unset !important;
  }

}

@media (max-width: 768px) {
  .LoginPage {
    .paddingCoachTop {
      border-radius: 0px !important;
    }
  }
}

@media (min-width: 480px) and (max-width: 640px) {
  .UserLoginPage {
    .alignItemPopUpLogin {
      position: relative;
      right: -120px;
      top: -10px;
    }
  }

  .RegistrationPage,
  .UserLoginPage {
    .GoogleIcon {
      margin-top: 11px;
      font-size: 33px;
    }

    .logoImage {
      width: 80px !important;
      height: 60px !important;
    }

    .DiscordIcon {
      display: inline-block;
      width: 32px !important;
      margin-top: 9px;
      position: relative;
      height: 32px !important;
      position: relative;
      overflow: hidden;
      vertical-align: middle;
    }

    .faceBookIcon {
      font-size: 50px;
      visibility: visible !important;
      position: relative;
      top: 9px;
    }

    .iconCard {
      margin-top: -28px !important;
      margin: 0px -2px;
      background: #111111 !important;
      height: 56px;
    }

    .marginTopOr {
      margin-top: 20px !important;
    }

    .form input {
      height: 20px !important;
    }

    .form button {
      height: 30px !important;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .InputDiv {
      padding: 26px 57px;
    }

    .spanHeading {
      font-size: 16px !important;
      margin-top: 20px;
      padding-top: 10px !important;
      margin-bottom: 20px !important;
    }

    .formLoginCard {
      padding: unset !important;
      margin: -16px !important;
    }

    .imageLogoPosLogin {

      left: -123px !important;
    }

    // .logoImage {
    //   width: 60px !important;
    //   height: 45px !important;
    // }

    .imageLogoPos {
      left: -148px;
    }

    .alignItemPopUp {
      right: -147px;
      top: -20px;
    }

    .fontFuturaMedium207 {
      font-size: 13px;
    }

    .FontLoto155 {
      font-size: 8px;
    }
  }

  .LoginPage {
    .fontRoboto577 {
      font-size: 30px !important;
    }

    .spanHeaderButton {
      padding: 6px 31px !important;
      margin: unset !important;

      svg {
        width: 17px;
      }
    }

    .fontRoboto254 {
      font-size: 20px !important;
    }

    .fontRoboto357 {
      font-size: 18px !important;
    }

    .spanTextExclusiveTextStyle {
      line-height: unset !important;
    }

    .CardStylesFooter {
      padding: 24px;
      padding-bottom: 40px !important;
    }

    .fontFuturaMedium207 {
      font-size: 11px !important;
    }

    .fontFuturaMedium157 {
      font-size: 10px !important;
    }

    .faIconsStyles {
      font-size: 17px;
    }

    .fafaIconsRow {
      padding: 0px 115px;
    }

    .spanComingFontStyles {
      line-height: unset !important;
    }

    .fontSamsung457 {
      font-size: 25px;
    }

    .fontSamsung257 {
      font-size: 16px;
    }

    .spanTextStyle {
      font-size: 20px !important;
    }

    .ChooseGameDiv {
      margin-top: 160px !important;
    }
  }
}

@media (min-width: 360px) and (max-width: 480px) {
  .UserLoginPage {
    .alignItemPopUpLogin {
      position: relative;
      right: -101px;
      top: -10px;
    }

    .imageLogoPosLogin {
      position: relative;
      top: -25px;
      left: -105px !important;
    }
  }

  .LoginPage {
    .fontRoboto577 {
      font-size: 25px !important;
    }



    .spanHeaderButton {
      padding: 6px 31px !important;
      margin: unset !important;

      svg {
        width: 20px;
      }
    }

    .fontRoboto254 {
      font-size: 20px !important;
    }

    .fontRoboto357 {
      font-size: 15px !important;
    }

    .spanTextExclusiveTextStyle {
      line-height: unset !important;
    }

    .CardStylesFooter {
      padding: 24px;
      padding-bottom: 40px !important;
    }

    .fontFuturaMedium207 {
      font-size: 7px !important;
    }

    .fontFuturaMedium157 {
      font-size: 7px !important;
    }

    .faIconsStyles {
      font-size: 17px;
    }

    .fafaIconsRow {
      padding: 0px 60px;
    }

    .spanComingFontStyles {
      line-height: unset !important;
    }
  }

  .RegistrationPage,
  .UserLoginPage {
    .GoogleIcon {
      margin-top: 10px;
      font-size: 30px;
    }

    .DiscordIcon {
      display: inline-block;
      width: 32px !important;
      margin-top: 9px;
      position: relative;
      height: 32px !important;
      position: relative;
      overflow: hidden;
      vertical-align: middle;
    }

    .faceBookIcon {
      font-size: 50px;
      visibility: visible !important;
      position: relative;
      top: 9px;
    }

    .iconCard {
      margin-top: -28px !important;
      margin: 0px -2px;
      background: #111111 !important;
      height: 56px;
    }

    .marginTopOr {
      margin-top: 20px !important;
    }

    .form input {
      height: 20px !important;
    }

    .form button {
      height: 30px !important;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .InputDiv {
      padding: 26px 24px;
    }

    .spanHeading {
      font-size: 12px !important;
      margin-top: 20px;
      padding-top: 10px !important;
      margin-bottom: 20px !important;
    }

    .formLoginCard {
      padding: unset !important;
      margin: -16px !important;
    }

    .logoImage {
      width: 60px !important;
      height: 45px !important;
    }

    .imageLogoPos {
      left: -101px;
    }

    .alignItemPopUp {
      right: -103px;
      top: -20px;
    }

    .fontFuturaMedium207 {
      font-size: 13px;
    }

    .FontLoto155 {
      font-size: 8px;
    }
  }
}

@media (max-width: 359px) {
  .UserLoginPage {
    .alignItemPopUpLogin {
      position: relative;
      right: -71px;
      top: -10px;
    }

    .imageLogoPosLogin {
      position: relative;
      top: -25px;
      left: -64px !important;
    }
  }

  .RegistrationPage,
  .UserLoginPage {
    .GoogleIcon {
      margin-top: 10px;
      font-size: 30px;
    }

    .DiscordIcon {
      display: inline-block;
      width: 32px !important;
      margin-top: 9px;
      position: relative;
      height: 32px !important;
      position: relative;
      overflow: hidden;
      vertical-align: middle;
    }

    .faceBookIcon {
      font-size: 50px;
      visibility: visible !important;
      position: relative;
      top: 9px;
    }

    .iconCard {
      margin-top: -28px !important;
      margin: 0px -2px;
      background: #111111 !important;
      height: 56px;
    }

    .marginTopOr {
      margin-top: 20px !important;
    }

    .form input {
      height: 20px !important;
    }

    .form button {
      height: 30px !important;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .InputDiv {
      padding: 16px 15px;
    }

    .spanHeading {
      font-size: 12px !important;
      margin-top: 20px;
      padding-top: 10px !important;
      margin-bottom: 20px !important;
    }

    .formLoginCard {
      padding: unset !important;
      margin: -16px !important;
    }

    .logoImage {
      width: 60px !important;
      height: 45px !important;
    }

    .imageLogoPos {
      left: -70px;
    }

    .alignItemPopUp {
      right: -69px;
      top: -20px;
    }

    .fontFuturaMedium207 {
      font-size: 13px;
    }

    .FontLoto155 {
      font-size: 8px;
    }
  }

  .LoginPage {


    .CardStylesGGOG {
      height: 250px !important;
    }

    .fontLato355 {
      font-size: 22px !important;
    }

    .fontLato203 {
      font-size: 18px !important;
    }

    .fontRoboto577 {
      font-size: 22px !important;
    }

    .HeaderImage {
      height: 250px !important;
    }

    .spanHeaderButton {
      padding: 6px 31px !important;
      margin: unset !important;

      svg {
        width: 15px;
      }
    }

    .fontRoboto254 {
      font-size: 15px !important;
    }

    .fontRoboto357 {
      font-size: 15px !important;
    }

    .spanTextExclusiveTextStyle {
      line-height: unset !important;
    }

    .CardStylesFooter {
      padding: 24px;
      padding-bottom: 30px !important;
    }

    .fontFuturaMedium207 {
      font-size: 6px !important;
    }

    .fontFuturaMedium157 {
      font-size: 6px;
    }

    .faIconsStyles {
      font-size: 17px;
    }

    .fafaIconsRow {
      padding: 0px 60px;
    }

    .spanComingFontStyles {
      line-height: unset !important;
    }

    .fontSamsung457 {
      font-size: 20px;
    }

    .fontSamsung257 {
      font-size: 13px;
    }


  }
}

@media (min-width: 768px) {
  .modal-dialog {
    max-width: 730px;
  }
}

@media (min-width: 640px) {
  .modal-dialog {
    max-width: 620px;
  }
}

@media (max-width: 990px) {

  .imageLogoPos,
  .imageLogoPosLogin {
    position: relative;
    top: -25px;
    left: -22px;
  }

  .LoginPage {
    .ChooseGameDiv {
      margin-top: 15 0px !important;
    }
  }
}

@media (max-width: 640px) {
  .imageLogoPos {
    position: relative;
    top: -25px;
    left: -22px;
  }

  .imageLogoPosLogin {
    position: relative;
    top: -25px;
    left: -15px;
  }

  .widthName {
    width: 146px;
    padding: 5px;
    margin-top: 75px !important;
  }
}

.verfiyEmailDiv {
  height: 450px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80%;
  margin-left: 10%;
  background: #111111;
}