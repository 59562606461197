.CardUserTournament {
  min-height: 100%;
  background: #0f0e0e;
  .react-select__control {
    outline: 0;
    background: #232323;
    color: #a99898;
    width: 100%;
    border: 0;
    border-radius: 5px;
    margin: 0 0 15px;
    padding: 8px;
    box-sizing: border-box;
    font-size: 14px;
    font-family: "Lato", sans-serif;
  }
  .SubmitButton{
    padding: 13px 57px;
    margin-top: 39px;
    background-color: #1b1d1e;
    display: flex;
    justify-content: center;
    color: #fff;
  }
  form {
    background-color: #000;
  }
  input {
    outline: 0;
    background: #232323;
    color: #a99898;
    width: 100%;
    border: 0;

    border-radius: 5px;
    margin: 0 0 15px;
    padding: 15px;
    box-sizing: border-box;
    font-size: 14px;
    font-family: "Lato", sans-serif;
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none !important;
    margin: 0;
  }
}
.playersAddForm{
    background-color: #232323 !important;
    height: 100%;
    h4{
       margin-right: 30%;
    }
    .userForm {
        padding: 13px 57px;
        margin-top: 39px;
        background-color: #337dcb;
        display: flex;
        justify-content: center;
        color: #fff;
      }

}
.HeaderRow{
    background: #141212;
    padding: 21px;
    color: #64dbc6;
}
.ContentRow{
    // background: #141212;
    padding: 21px;
    color: #f7f7fa;
    // margin-top: 10px;
    text-align: start;
    display: flex;
}
.IndexDatatableNeoRow {
  display: flex;
  white-space: initial;
  flex-wrap: nowrap !important;
  // height: 50px;
  background-color: rgb(13, 13, 13);
  color: #fff;
}
.IndexDatatableNeoCol {
  text-align: left;
  padding-left: 50px;
}
.IndexDatatableNeoCol1 {
  // text-align: left;
  // padding-left: 43px;
  height: 50px;
  .rs-picker{
    border: 1px solid #E6E6E6;;
  }
  .rs-picker-toggle-value {
    color: #6E6E6E !important;
    font-size: 13px;
    padding-top: 2px;
    height: 26px;
    left:23px;
    margin-left: 15px;
}
.rs-picker{
  :placeholder{
    position: relative;
    left: 50px !important;
  }
}
}
